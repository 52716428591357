// BlogPostSharedSettings.tsx
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Chips } from "primereact/chips";
import CustomChip from "../../components/CustomChip";
import GenericDescriptionInput from "../formComponentes/GenericDescriptionInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";

function SmsSharedSettings() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
      >
        name="inputParams.legalInformation"
        label="מידע והצהרות (אופציונלי)"
        placeholder="כל מידע משפטי או הצהרות שצריך לכלול, כגון תנאים והגבלות או הוראות לביטול הסכמה."
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
      >
        name="inputParams.additionalInformation"
        label="מידע והצהרות (אופציונלי)"
        placeholder="כל מידע נוסף שתרצו להוסיף"
      />
    </div>
  );
}

export default SmsSharedSettings;
