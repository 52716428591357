import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "../modals/ModalHeader";
import { InputTextarea } from "primereact/inputtextarea";
import { ContentCardIcons } from "../website/overview/contentCards/iconMappings";
import { Divider } from "primereact/divider";
import { useCallback, useMemo, useRef, useState } from "react";
import Button from "../common/form/Button";
import AddPlatformDropDown from "../AddPlatformDropDown";
import IgPreview from "./postPreviews/IgPreview";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import ClockSVG from "../common/ClockSVG";
import { addLocale } from "primereact/api";
import { PostEntity, PostEntityType, PostStatusType } from "neword-core";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useController,
  useFieldArray,
  useForm,
} from "react-hook-form";
import AttachmentInput from "../common/form/AttachmentInput";
import FbPreview from "./postPreviews/fbPreview";
import { postTypeToForm } from "./forms/postTypeToForm";
import postService from "../../core/services/post.service";
import { useParams } from "react-router";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as TrashIcon } from "../../assets/Icons/Trash.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { postState, postsSummariesState } from "../../state/postState";
import DateInput from "./PostDateInput";
import PostDateInput from "./PostDateInput";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { fill } from "lodash";
import Badge from "../common/Badge";
import { Color } from "../../core/theme/types/color";
import { Theme } from "../../core/theme/theme";
import BadgeDropDown from "../common/form/BadgeDropDown";

const DialogStyled = styled(Dialog)`
  .p-dialog-content {
    padding: 0px;
    overflow: visible;
  }

  .p-dialog-header {
    padding: 0px !important;
  }

  .p-dialog-footer {
    padding: 1.5em !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

interface Props {
  post: PostEntity;
  onHide: () => void;
}

const PlatformsWrapper = styled.div`
  border-right: 1px solid var(--border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
`;

const RemoveSocial = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--title-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
`;

const PlatformIconWrapper = styled.div<{
  backgroundColor?: string;
  active?: boolean;
  dashed?: boolean;
  iconColor?: string;
}>`
  &:hover ${RemoveSocial} {
    opacity: 1;
    pointer-events: all;
  }
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: ${(props) =>
    props.active ? props.backgroundColor : "var(--border-color)"};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dashed ? "1px dashed lightgrey" : "none")};
  svg {
    width: 20px;
    height: 20px;
    fill: ${(props) =>
      props.iconColor ? props.iconColor : "white !important"};

    path {
      fill: ${(props) =>
        props.iconColor ? props.iconColor : "white !important"};
    }
  }
`;

const TopMenu = styled.div`
  display: flex;
  gap: 20px;

  border-bottom: 1px solid var(--border-color);
  align-items: center;
  justify-content: end;
`;

const SocialWrapper = styled.div`
  width: 80px;
`;

const AddSocialButton = styled.div`
  cursor: pointer;

  &:hover {
    background: var(--light-bg);
  }
`;

const PreviewPostModal: React.FC<Props> = ({ onHide, post }) => {
  const addSocialMenu = useRef<Menu>(null);
  const { websiteId } = useParams();

  const [_, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  const methods = useForm<PostEntity>({
    defaultValues: { ...post, scheduleDate: new Date(post.scheduleDate) },
  });

  const FormComponent = postTypeToForm[methods.getValues("type")];

  const handleSubmit: SubmitHandler<PostEntity> = async (values) => {
    postService
      .updatePost(values)
      .then(() =>
        setPosts((currentPosts) =>
          currentPosts.map((p) => (p.id === post.id ? { ...p, ...values } : p))
        )
      );

    onHide();
  };

  const getBadgeColor = useCallback(
    (status: PostStatusType): { color: Color; text: string } => {
      switch (status) {
        case PostStatusType.PUBLISHED:
          return { color: "purple", text: "פורסם" }; // Make sure the return value matches keyof ThemeType["colors"]
        case PostStatusType.PENDING:
          return { color: "yellow", text: "ממתין לאישור" }; // Change to a valid key like "yellow"
        case PostStatusType.APPROVED:
          return { color: "success", text: "אושר" }; // Update this as per the keys available
        case PostStatusType.REJECTED:
          return { color: "danger", text: "נדחה" }; // This should match exactly one of the keys in colors
        default:
          return { color: "title", text: "פורסם" }; // This should be one of the valid keys as well
      }
    },
    []
  );

  const onDelet = async () => {
    await postService.deletePost(post.id);
    toast("הפוסט נמחק בהצלחה");
    setPosts((prev) => prev.filter((p) => p.id != post.id));
    onHide();
  };

  let menuitems = Object.entries(ContentCardIcons)
    .filter(([key, value]) => !!value.iconPlain) // Filter out entries without iconPlain
    .map(([key, value]) => ({
      template: () => (
        <AddSocialButton className="p-3 flex gap-10  align-items-center">
          <PlatformIconWrapper backgroundColor={value.color} active>
            {value.iconPlain && <value.iconPlain />}
          </PlatformIconWrapper>
          {value.socialPostingText && value.socialPostingText}
        </AddSocialButton>
      ),
    }));

  return (
    <FormProvider {...methods}>
      <form>
        <DialogStyled
          header={<ModalHeader OnClose={onHide} />}
          closable={false}
          visible
          position={"center"}
          style={{
            width: "60vw",
            margin: "0",
            boxShadow: "none",

            borderLeft: "solid 1px var(--border-color)",
            borderRadius: "0px",
            maxHeight: "100%",
            padding: "0",
          }}
          onHide={onHide}
          draggable={false}
          resizable={false}
          footer={
            <div className="grid m-0">
              <div className="col-9 flex">
                <Button
                  onClick={methods.handleSubmit(handleSubmit)}
                  bgColor="purple"
                  primary
                  className="mb-0"
                >
                  שמירת שינויים
                </Button>
                <Button
                  icon={<TrashIcon style={{ fill: "red" }} />}
                  onClick={onDelet}
                  bgColor="transparent"
                  textColor="danger"
                  className="mb-0 mr-2"
                >
                  מחיקת פוסט
                </Button>
                <div className="flex gap-10 align-items-center"></div>
              </div>
              <div className="col-3 flex">
                <PostDateInput />
              </div>
            </div>
          }
        >
          <div className="flex m-0">
            <Tooltip target=".remove-icon" />
            <Menu model={menuitems} popup ref={addSocialMenu} id="socialmenu" />
            <Wrapper>
              <TopMenu>
                <div className="status">
                  <label htmlFor="">סטטוס: </label>
                  <Controller
                    name="status"
                    control={methods.control}
                    render={({ field }) => {
                      // Create options array
                      const options = Object.values(PostStatusType).map(
                        (status) => ({
                          value: status,
                          label: getBadgeColor(status).text,
                          color: getBadgeColor(status).color,
                        })
                      );

                      // Handler for when the dropdown value changes
                      const handleStatusChange = async (
                        selectedValue: PostStatusType
                      ) => {
                        try {
                          // Update the form value using field.onChange
                          field.onChange(selectedValue);

                          // Update the backend using postService
                          const updatedPost =
                            await postService.updatePostStatus(
                              post.id,
                              selectedValue
                            );

                          // Update the local posts state if available
                          setPosts((oldPosts) =>
                            oldPosts.map((p) =>
                              p.id === post.id ? updatedPost : p
                            )
                          );

                          // Show success notification
                          toast.success("הסטטוס עודכן בהצלחה");
                        } catch (error) {
                          // Handle any errors that may occur
                          toast.error("שגיאה בעדכון הסטטוס");
                        }
                      };

                      return (
                        <BadgeDropDown
                          value={field.value}
                          options={options}
                          onChange={(selectedValue) =>
                            handleStatusChange(selectedValue)
                          }
                        />
                      );
                    }}
                  />
                </div>
              </TopMenu>
              {FormComponent ? (
                <FormComponent name="data" />
              ) : (
                <div>no form</div>
              )}
            </Wrapper>
          </div>
        </DialogStyled>
      </form>
    </FormProvider>
  );
};

export default PreviewPostModal;
