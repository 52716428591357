import React, { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/InstagramIcon.svg";
import Card from "../../common/Card";
import {
  FieldValues,
  Path,
  PathString,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { PostEntity, PostEntityType } from "neword-core";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import InstagramPreview from "../../../assets/images/instagramPreview.png"; // Add Facebook preview image
import { Galleria } from "primereact/galleria";
import { isVideo } from "../../../common/utils/isVideo";

const Wrapper = styled(Card)`
  position: relative;
  padding: 10px;
  width: 100%;
  min-height: 400px;
`;

const IconsWrapper = styled.div`
  flex-direction: row-reverse;
  margin-top: 10px;
  opacity: 0.5;
  svg {
    width: 55px;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const InstagramPreviewWrapper = styled.img`
  border-radius: 6px;
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container */
`;
const SyledGalleria = styled(Galleria)`
  /* width: 100%;
  padding-top: 52.5%; /* Facebook post image aspect ratio */
  position: relative;
  margin-bottom: 10px;
  min-height: 230px; */
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const PostImage = styled.img`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; */
  width: 200px;
  height: 200px;
  border-radius: 8px;
`;

const ImageWrapper = styled.div`
  width: 100%; /* Full width of the container */
  padding-top: 40px; /* This creates the 1:1 aspect ratio */
  position: relative;
`;

function IgPreview<FormDataType extends FieldValues>({
  name,
}: {
  name: Path<FormDataType>;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({ control: methods.control, name: name });

  const imageUrl = useMemo(() => {
    return values?.imageUrls || InstagramPreview; // Default to empty string if no image is available
  }, [values]);

  return (
    <Wrapper className="col-4">
      <TopSection></TopSection>
      <IconWrapper>
        <Logo />
      </IconWrapper>
      <ImageWrapper>
        <SyledGalleria
          value={imageUrl.length > 0 ? imageUrl : [InstagramPreview]}
          // style={{ maxWidth: "640px" }}
          // changeItemOnIndicatorHover
          showThumbnails={false}
          showIndicators
          item={(item: string) => {
            return isVideo(item) ? (
              <video
                src={item}
                controls
                style={{ width: "300px", height: "200px" }}
              />
            ) : (
              <PostImage src={item} />
            );
          }}
        />{" "}
      </ImageWrapper>
      <AcountText className="mt-3 flex row-revers">{website?.name}</AcountText>
      <ContentWrapper className="mt-1 pb-4">
        {values && values.message}
      </ContentWrapper>
    </Wrapper>
  );
}

export default IgPreview;
