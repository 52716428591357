// BlogPostSharedSettings.tsx
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Chips } from "primereact/chips";
import CustomChip from "../../components/CustomChip";
import GenericDescriptionInput from "../formComponentes/GenericDescriptionInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocChipInput from "../formComponentes/DocChipInput";

function BioSharedSettings() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<DocumentEntity & { type: DocumentEntityType }>
        name="inputParams.cta"
        label="קריאה לפעולה (אופציונלי)"
        placeholder={`לדוגמה ״הרשמה לניוזלטר שלנו״ או ״הובלה לקניית  המוצר שלנו https://neword/product.ai”`}
        inputStyle={{ height: 60 }}
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
      >
        name="inputParams.additionalInformation"
        label="מידע נוסף (אופציונלי)"
        placeholder="כל מידע נוסף שתרצו להוסיף"
        hideRephrase
      />
    </div>
  );
}

export default BioSharedSettings;
