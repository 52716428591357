export const isImage = (url: string) => {
  if (!url) return false;

  const allowedImageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "heic",
    "heif",
    "webp",
    "avif",
  ];

  const extension = url.split(".").pop()?.toLowerCase();

  if (!extension) return false;

  return allowedImageExtensions.includes(extension);
};
