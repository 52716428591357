import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as ColoredGoogle } from "../../../assets/Icons/ColoredGoogle.svg";
import { ReactComponent as Sparkles } from "../../../assets/Icons/Sparkles.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";
import Link from "../../common/Link";
import Badge from "../../common/Badge";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const KeywordsContainer = styled.div`
  margin-top: 0px;
`;

const KeywordsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 0.625rem;
  flex-wrap: wrap;

  & .kw-bg {
    /* border: solid 1px var(--border-color); */
    color: var(--primary-purple);
    background: var(--light-bg);
  }
`;

const KeywordBadge = styled(Link)`
  border-radius: 900px;

  &:hover .kw-overly svg path {
    opacity: 1;
  }
  & .close path {
    fill: var(--primary-purple);
  }
`;

const ProductNameWrapper = styled.div`
  border-radius: 8px;
  background: var(--Card-Background, #f6f9fc);
  padding: 10px 20px;
  text-align: center;
  color: #000;
  font-family: "Noto Sans Hebrew";
  font-size: 12.211px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.432px */
  letter-spacing: -0.366px;
`;
const SparklesIcon = styled(Sparkles)`
  width: 12px;
  height: 12px;
  & path {
    fill: var(--primary-purple);
  }
`;
const ColoredGoogleIcom = styled(ColoredGoogle)`
  width: 20px;
  height: 20px;
`;

const GoogleAdsKeywordsBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));

    const wrapperRef = useRef<HTMLDivElement>(null);
    const { getValues, control } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.GOOGLE_ADS_KEYWORDS }
    >();

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const value = getValues(`output.${blockIndex}`);
        let text = "";
        value.adKeywords.map((keyword) => {
          text = text + keyword + " \n";
        });
        return text;
      },
    }));

    const blockValues = getValues(`output.${blockIndex}`);

    return (
      <Wrapper ref={wrapperRef}>
        <div className="flex     justify-content-center mb-3">
          {" "}
          <ColoredGoogleIcom />
        </div>

        <ProductNameWrapper>{blockValues.keywordsType}</ProductNameWrapper>

        <KeywordsContainer>
          {/* <label>{keywordsHeader}</label> */}
          <KeywordsWrapper>
            {blockValues.adKeywords.map((keyword, index) => (
              <KeywordBadge key={keyword + index} className="kw-bg">
                <Badge
                  icon={<SparklesIcon />}
                  bgColor="bg"
                  clickable
                  textColor="purple"
                  large
                  // onClick={() => {
                  //   remove(index);
                  // }}
                >
                  {keyword}
                </Badge>
              </KeywordBadge>
            ))}
          </KeywordsWrapper>
        </KeywordsContainer>
      </Wrapper>
    );
  }
);

export default GoogleAdsKeywordsBlock;
