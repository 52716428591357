import { atomFamily } from "recoil";
import { DocumentEntity, PostEntity } from "neword-core";
import documentService from "../core/services/document.service";
import postService from "../core/services/post.service";

export const postState = atomFamily<PostEntity, string>({
  key: `postState`,
  default: (documentId: string) => postService.getPostById(documentId),
});

export const postsSummariesState = atomFamily<PostEntity[], string>({
  key: `postSummariesState`,
  default: (websiteId: string) => postService.getPostsByWebsiteId(websiteId),
});
