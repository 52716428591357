import { ServiceBase } from "./service.base";
import { PostEntity, PostStatusType } from "neword-core";

class PostService extends ServiceBase {
  prefix = "/post";

  getPostById(postId: string) {
    return this.get<PostEntity>(`/${postId}`);
  }

  getPostsByWebsiteId(websiteId: string) {
    return this.get<PostEntity[]>(`/website/${websiteId}`);
  }

  createDocument(posts: PostEntity[], websiteId: string) {
    return this.post<string>(`website/${websiteId}/create`, { posts });
  }

  publishPost(postId: string) {
    return this.post<PostEntity>(`/${postId}/publish`);
  }

  updatePost(post: PostEntity) {
    return this.post<PostEntity>(`/update`, post);
  }

  updatePostStatus(postId: string, status: PostStatusType) {
    return this.patch<PostEntity>(`/${postId}/status/${status}`);
  }

  deletePost(postId: string) {
    return this.delete<void>(`/${postId}`);
  }
}
const postService = new PostService();
export default postService;
