import { ReactComponent as NewordTextLogo } from "../../assets/Icons/NewordTextLogo.svg";
import styled from "styled-components";

const Title = styled.h1`
  color: var(--main-title-color, #0a2540);
  text-align: center;
  font-family: "Assistant";
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 3rem */
  letter-spacing: -0.12rem;
  text-transform: capitalize;
  margin: 60px 0;
  width: 37.625rem;
  & span {
    color: var(--main-purple, #a960ee);
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 30px;

  svg {
    width: 120px;
    height: 30px;
  }

  @media only screen and (max-width: 1000px) {
    position: relative;
    right: auto;
    top: auto;
  }
`;

const AuthHeader: React.FC = () => {
  return (
    <div className="flex align-items-center flex-column">
      <LogoWrapper>
        <NewordTextLogo />
      </LogoWrapper>

      <Title>היי, שנתחבר?</Title>
    </div>
  );
};
export default AuthHeader;
