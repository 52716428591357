export const isVideo = (url: string) => {
  if (!url) return false;

  const allowedImageExtensions = ["mp4", "mov"];

  const extension = url.split(".").pop()?.toLowerCase();
  if (!extension) return false;

  return allowedImageExtensions.includes(extension);
};
