// BlogPostSharedSettings.tsx
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Chips } from "primereact/chips";
import CustomChip from "../../components/CustomChip";
import GenericDescriptionInput from "../formComponentes/GenericDescriptionInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocChipInput from "../formComponentes/DocChipInput";

function SocialSharedSettings() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
      >
        name="inputParams.cta"
        label="קריאה לפעולה (אופציונלי)"
        placeholder={`לדוגמה ״הרשמה לניוזלטר שלנו״ או ״הובלה לקניית  המוצר שלנו https://neword/product.ai”`}
        inputStyle={{ height: 60 }}
      />
      <DocChipInput<
        DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
      >
        name="inputParams.hashtags"
        label="האשטגים (אופציונלי)"
        placeholder="יש ללחוץ Enter על מנת להכניס את ההאשטג"
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
      >
        name="inputParams.additionalInformation"
        label="מידע נוסף (אופציונלי)"
        placeholder="כל מידע נוסף שתרצו להוסיף"
        hideRephrase
      />
    </div>
  );
}

export default SocialSharedSettings;
