import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { BlockOutputProps } from "./types/blockOutputProps";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/Neword.svg";
import { ReactComponent as InstagramLogo } from "../../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as RephraseIcon } from "../../../assets/Icons/refresh.svg";
import InstagramPreview from "../../../assets/images/instagramPreview.png";
import { ReactComponent as InstagramLike } from "../../../assets/Icons/InstagramLike.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const HoverContainer = styled.div`
  position: relative;
  &:hover .rephrase-button {
    opacity: 1;
    pointer-events: all;
  }
`;

const RephraseButtonWrapper = styled.div<{ isEditing: boolean }>`
  border-radius: 8px;
  margin-top: ${({ isEditing }) => (isEditing ? "10px" : "-16px")};
  display: flex;
  cursor: pointer;
  opacity: ${({ isEditing }) => (isEditing ? "1" : "0")};
  pointer-events: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: 0.15s ease-in-out;
  span {
    color: var(--primary-purple);
  }
  svg {
    width: 10px;
  }
  svg path {
    width: 10px;
    fill: var(--primary-purple);
  }
`;

const ContentWrapper = styled.div<{ isEditing: boolean }>`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  transition: 0.1s ease-in-out;
  align-items: flex-start;

  /* Fixed border and padding logic */
  border: ${({ isEditing }) =>
    isEditing ? "1px solid #e6e6e6" : "1px solid white"};
  padding: ${({ isEditing }) => (isEditing ? "15px" : "0")};
  background: ${({ isEditing }) => (isEditing ? "#f9f9f9" : "transparent")};

  &:hover {
    ${RephraseButtonWrapper} {
      opacity: 1;
      pointer-events: all;
      margin-top: 10px;
    }
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const InstagramPreviewWrapper = styled.img<{ isPreview: boolean }>`
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ isPreview }) => (isPreview ? 0.5 : 1)};
  object-fit: cover; /* Ensures the image fills the container */
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 90%; /* This creates the 1:1 aspect ratio */
  position: relative;
`;

const InstagramCaptionBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));
    const [isHovered, setIsHovered] = useState(false);

    const form = useFormContext<
      DocumentEntity & { type: DocumentEntityType.INSTAGRAM_CAPTION }
    >();
    const imageUrl = form.getValues("inputParams.imageUrl") ?? InstagramPreview;

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const values = form.getValues();
        return values.output[blockIndex].captionText || "";
      },
    }));

    return (
      <Wrapper className="col-4">
        <TopSection></TopSection>
        <IconWrapper>
          <InstagramLogo />
        </IconWrapper>
        <ImageWrapper>
          <InstagramPreviewWrapper
            isPreview={imageUrl == InstagramPreview}
            src={imageUrl}
          />
        </ImageWrapper>
        <AcountText className="mt-3 flex row-revers">
          {website?.name}
        </AcountText>
        <HoverContainer>
          <ContentWrapper isEditing={isEditing}>
            <EditableTextArea<DocumentEntityType.INSTAGRAM_CAPTION>
              fieldName={`output.${blockIndex}.captionText`}
              handleBlur={handleBlur}
              isEditing={isEditing}
              blockIndex={blockIndex}
            />
          </ContentWrapper>
        </HoverContainer>
      </Wrapper>
    );
  }
);

export default InstagramCaptionBlock;
