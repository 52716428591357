import {
  Controller,
  FieldValues,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { Menu } from "primereact/menu";

import ContentSuggestion from "../../components/ContentSuggestion";
import RephraseButton from "../../../common/form/RephraseButton";
import AttachmentInput from "../../../common/form/AttachmentInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";

const StyledInputText = styled.textarea`
  outline: none; /* This removes the default outline that appears when the input is focused */
  height: 100px;
  resize: auto; /* Disable manual resizing */
  font-family: inherit; /* Inherit font family */
  border-radius: 6px;
  border: 1px solid var(--Input-BorderColor, #e6e6e6);
  padding: 10px;
  margin-top: 10px;
`;
const SuggestedKeywordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
`;
const InputTextWrapper = styled.div`
  /* padding: 15px; */
  border-radius: 8px;
  /* border: 1px solid var(--Input-BorderColor, #e6e6e6); */
  margin-top: 17px;
  /* overflow: scroll; */
  &.p-invalid {
    border-color: red;
  }
`;
const SmallLabel = styled.label`
  font-family: "Assistant";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  letter-spacing: -0.36px;
  cursor: pointer;
`;

const IconStyle = styled.i`
  font-size: 12px;
  transition-duration: 0.1s;
  padding-right: 8px;
`;

const AdditionalOptions = styled.div`
  font-size: 14px;
  padding: 0;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: black !important;
  cursor: pointer !important;

  ${IconStyle} {
    position: relative;
    top: 0px;
  }

  &:hover {
    ${IconStyle} {
      top: 2px !important;
    }
  }
`;
const WrapperImage = styled.div`
  /* border-top: solid 1px var(--border-color); */
  /* padding-top: 10px; */
`;
interface GenericInputProps<T> {
  name: Path<T>;
  label: string;
  showQuickOptions?: boolean;
  placeholder?: string;
  inputStyle?: React.CSSProperties;
  hideRephrase?: boolean;
  showAttchment?: boolean;
  generateText?: string;
  reGenerateText?: string;
}

function GenericDescriptionInput<T extends FieldValues>({
  name,
  label,
  showQuickOptions,
  hideRephrase,
  placeholder,
  inputStyle,
  showAttchment,
  generateText,
  reGenerateText,
}: GenericInputProps<T>) {
  const [text, setText] = useState<string>("");

  const {
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<T>();

  const onEnter = async (caption: string) => {
    setValue(name, caption as any); // adjust type if needed
    trigger(name);
  };

  let items = [
    {
      template: () => {
        return (
          <SuggestedKeywordWrapper>
            <ContentSuggestion onClickKeyword={onEnter} />
          </SuggestedKeywordWrapper>
        );
      },
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const menuExtra = useRef<Menu>(null);

  const genereate = generateText
    ? generateText
    : "תרשום לי ישר בלי הקדמה ,תיצור לי נושא לכתיבה ";

  const reGenereate = generateText
    ? generateText
    : "הטקסט הבא הוא תיאור, במידה והוא דל, תרחיב אותו קצת, תוסיף עוד תוכן ללא סיכום וללא הקדמה";

  return (
    <div className="w-full step-2-wt">
      <Controller
        name={name}
        control={control}
        render={({ fieldState, field }) => (
          <InputTextWrapper className={errors?.[name] ? "p-invalid" : ""}>
            <div className="flex justify-content-between">
              <SmallLabel>{label}</SmallLabel>
              <Menu
                model={items}
                popup
                ref={menuExtra}
                id="popup_menu_content"
              />
              {showQuickOptions && (
                <AdditionalOptions
                  onClick={(event) => menuExtra.current?.toggle(event)}
                  aria-controls="popup_menu_content"
                  aria-haspopup
                  className="flex"
                >
                  <SmallLabel>אפשרויות מהירות</SmallLabel>
                  <IconStyle className="pi pi-angle-down"></IconStyle>
                </AdditionalOptions>
              )}
            </div>

            <StyledInputText
              value={field.value}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(e);
                handleChange(e);
              }}
              style={inputStyle}
              className={"w-full mb-1 " + (errors?.[name] ? "p-invalid" : "")}
            />
            {!hideRephrase && (
              <div className="flex justify-content-between align-items-start">
                <RephraseButton
                  id={name}
                  onChange={field.onChange}
                  content={field.value}
                  prompt={text ? reGenereate : genereate}
                >
                  <span>{text ? "נסח בשבילי מחדש" : "צור בשבילי"}</span>
                </RephraseButton>
                {showAttchment && (
                  <WrapperImage className="">
                    <AttachmentInput<DocumentEntity>
                      name="inputParams.imageUrl"
                      // validate={valdiateImageOrDescription}
                    />
                  </WrapperImage>
                )}
              </div>
            )}
          </InputTextWrapper>
        )}
      />
    </div>
  );
}

export default GenericDescriptionInput;
