import { ServiceBase } from "./service.base";
import { DocumentEntity, DocumentEntityType } from "neword-core";

class DocumentService extends ServiceBase {
  prefix = "/document";

  getDocumentById<T extends DocumentEntityType>(documentId: string) {
    return this.get<DocumentEntity>(`/${documentId}`);
  }

  getDocumentsByWebsiteId(websiteId: string) {
    return this.get<DocumentEntity[]>(`/website/${websiteId}`);
  }

  createDocument(docType: DocumentEntityType, websiteId: string) {
    return this.post<string>(`/`, { docType, websiteId });
  }

  updateDocument(document: Partial<DocumentEntity>) {
    return this.post<DocumentEntity>(`/update/${document.id}`, document);
  }

  generateDocument(
    documentId: string,
    websiteId: string,
    params: DocumentEntity["inputParams"]
  ) {
    return this.post<DocumentEntity>(
      `/website/${websiteId}/generate/${documentId}`,
      params
    );
  }

  deleteDocument(documentId: string) {
    return this.delete<void>(`/${documentId}`);
  }
  rewriteBlock(
    documentId: string,
    block: DocumentEntity["output"][number],
    change: string
  ) {
    return this.post<DocumentEntity["output"][number]>(
      `/rewriteBlock/${documentId}`,
      { block, change }
    );
  }
}
const documentService = new DocumentService();
export default documentService;
