import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import { AccordionProps } from "./types/AccordionProps";

import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

export const notificationSmsAccordions: AccordionProps[] = [
  {
    title: "",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.NOTIFICATION_SMS }
        >
          name="inputParams.notificationType"
          label="סוג ההתראה"
          placeholder={`האופי הספציפי של ההתראה (למשל, "שינוי סיסמה", "הפרעת שירות", "זוהתה פעילות בחשבון").`}
          inputStyle={{ height: 60 }}
        />
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.NOTIFICATION_SMS }
        >
          name="inputParams.notificationDetails"
          label="פרטי ההתראה"
          placeholder={`תיאור קצר של מה על ההתראה (למשל, "סיסמת החשבון שלך שונתה לאחרונה", "זיהינו התחברות ממכשיר חדש").
          כל מידע רלוונטי לגבי האירוע (למשל, תאריך ושעת הפעילות, אופי ההפרעה בשירות).`}
        />{" "}
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.NOTIFICATION_SMS }
        >
          name="inputParams.supportContact"
          label="כיצד לקוחות יכולים לפנות אם יש להם שאלות או זקוקים לסיוע? (אופציונלי)"
          placeholder={`לדוגמה: ״דוא"ל: support@yourstore.com״, ״לייב צ'אט: זמין באתר שלנו״
          ״מספר טלפון לתמיכת לקוחות: 1-800-555-1234״
          `}
          hideRephrase
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <small className="pr-2 mb-3">* ניתן לבחור יותר מאחד</small>
        <DocumentTone<
          DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
        >
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
