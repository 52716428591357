import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/BlueIcon.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/Icons/facebook/FacebookLogo.svg"; // Add Facebook logo
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { ReactComponent as FacebookLike } from "../../../assets/Icons/facebook/FacebookLike.svg"; // Add Facebook like icon
import { ReactComponent as FacebookComment } from "../../../assets/Icons/facebook/FacebookComment.svg"; // Add Facebook like icon
import { ReactComponent as FacebookShare } from "../../../assets/Icons/facebook/FacebookShare.svg"; // Add Facebook like icon
import { ReactComponent as FacebookLikeCount } from "../../../assets/Icons/facebook/FacebookLikeCount.svg"; // Add Facebook like icon

import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  justify-content: space-between;
  font-size: 14px;
  svg {
    width: 15px;
  }
`;

const ButtonCta = styled.button`
  box-sizing: border-box;

  width: 100px;
  height: 30px;

  border: 0.610169px solid #1e6dff;
  border-radius: 3.66102px;
  color: #1e6dff;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 6.712px */
  letter-spacing: -0.183px;
  background-color: white;
`;
const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`;
const BottomDiv = styled.div`
  position: absolute;
  bottom: 10px; /* Add a little distance to the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  width: 90%; /* Optional: You can adjust this based on your needs */
  padding: 10px;
  background-color: white; /* Semi-transparent background */
  color: black; /* Optional: Text color */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 5px; /* Optional: Add some border radius */
`;
const ContentWrapper = styled.div`
  font-size: 16px;
  display: flex;
  color: var(--title-color);
`;

const LeftTopMenu = styled.div`
  display: flex;
  align-items: center;

  opacity: 0.3;
`;

const FacebookPreviewWrapper = styled.img`
  border-radius: 6px;
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container */
`;

const EditableInput = styled.textarea<{ isEditing: boolean }>`
  font-size: 12.21px;
  border: ${({ isEditing }) =>
    isEditing ? "1px solid var(--Input-BorderColor, #e6e6e6)" : "none"};
  background: transparent;
  outline: none;
  width: 100%;
  height: auto;
  font-family: inherit;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  width: 100%; /* Full width of the container */
  padding-top: 90%; /* This creates the 1:1 aspect ratio */
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const FacebookSponsoredAdBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));

    const { getValues } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.FACEBOOK_SPONSORED_AD }
    >();
    const imageUrl = FacebookPreview;
    const wrapperRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const output = getValues(`output.${blockIndex}`);
        return output.AdHeadline;
      },
    }));
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          handleBlur(blockIndex);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleBlur]);

    return (
      <Wrapper className="col-4 mb-2" ref={wrapperRef}>
        <TopSection>
          {/* <LeftTopMenu>
            <AccountText className="ml-1">{website?.name}</AccountText>
            <Neword />
          </LeftTopMenu> */}
          <IconWrapper>
            <FacebookLogo />
          </IconWrapper>
        </TopSection>

        <ContentWrapper className="mt-1">
          <EditableTextArea<DocumentEntityType.FACEBOOK_SPONSORED_AD>
            fieldName={`output.${blockIndex}.captionText`}
            blockIndex={blockIndex}
            isEditing={isEditing}
          />
        </ContentWrapper>
        <ImageWrapper>
          <FacebookPreviewWrapper src={imageUrl} />
          <BottomDiv
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="  ">
              <EditableTextArea<DocumentEntityType.FACEBOOK_SPONSORED_AD>
                fieldName={`output.${blockIndex}.AdHeadline`}
                isEditing={isEditing}
                blockIndex={blockIndex}
                style={{ fontWeight: 800, fontSize: 12 }}
              />
              <EditableTextArea<DocumentEntityType.FACEBOOK_SPONSORED_AD>
                fieldName={`output.${blockIndex}.captionText`}
                isEditing={isEditing}
                blockIndex={blockIndex}
                style={{ fontWeight: 600, fontSize: 12 }}
              />
            </div>

            <ButtonCta type="button">
              {getValues(`output.${blockIndex}.callToActionButton`)}
            </ButtonCta>
          </BottomDiv>
        </ImageWrapper>

        <IconsWrapper>
          <div className="flex">
            <FacebookShare className="ml-2" />
            שיתוף
          </div>
          <div className="flex">
            <FacebookComment className="ml-2" />
            תגובה
          </div>

          <div className="flex">
            <FacebookLike className="ml-2" />
            לייק
          </div>
        </IconsWrapper>
      </Wrapper>
    );
  }
);

export default FacebookSponsoredAdBlock;
