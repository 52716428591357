import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FieldValues,
  useForm,
  DeepPartial,
  DefaultValues,
  FieldErrors,
} from "react-hook-form";
import FormStepType from "../components/common/form/types/formStepType";
import { set } from "lodash";

function useMultiStepForm<FormType extends DeepPartial<FieldValues>>(
  steps: FormStepType<FormType>[],
  onSubmitCallback: any,
  initialValues?: DefaultValues<FormType>
) {
  const [activeStep, setActiveStep] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(33.5);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    mode: "all", // or 'onChange' to validate on value changes
  });

  const [isFormValid, setIsFormValid] = useState(methods.formState.isValid);

  const watchValues = methods.watch(steps[activeStep].fields as any);

  const progressUnit = useMemo(() => 100 / steps.length, []);

  useEffect(() => {
    methods.trigger(steps[activeStep].fields).then(setIsFormValid);
  }, [...watchValues, activeStep]);

  const component = useMemo(
    () => steps[activeStep].component,
    [activeStep, steps]
  );

  const navigateNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
    setProgressPercentage((prevPercentage) => prevPercentage + progressUnit);
  };

  const navigatePrevStep = async () => {
    methods.clearErrors();
    setActiveStep((prevStep) => prevStep - 1);
    setProgressPercentage((prevPercentage) => prevPercentage - progressUnit);
  };

  const getError = useCallback(
    (errors: FieldErrors<FieldValues>, path = "") => {
      let err = "";
      Object.keys(errors).forEach((key) => {
        const currentPath = path ? `${path}.${key}` : key;
        const error = errors[key];

        // Check if it's a nested error object
        if (error && typeof error === "object" && error.type === undefined) {
          // Recurse into nested objects
          err = getError(error as any, currentPath);
        } else {
          err = (error?.message as string) ?? "";
        }
      });

      return err;
    },
    []
  );

  const error = useMemo(() => {
    if (!methods.formState.isValid) {
      return getError(methods.formState.errors);
    }
    return "";
  }, [getError, methods.formState, methods.formState.isValid, activeStep]);

  const onClickNextButton = async () => {
    if (isFormValid) {
      if (activeStep < steps.length - 1) {
        navigateNextStep();
      } else {
        await onSubmitCallback();
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await onSubmitCallback();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    methods,
    activeStep,
    progressPercentage,
    isLoading,
    showBackButton: activeStep > 0,
    component,
    onClickNextButton,
    onSubmit,
    navigateNextStep,
    navigatePrevStep,
    error,
    isFormValid,
  };
}
export default useMultiStepForm;
