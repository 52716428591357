import React, { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/facebook/FacebookLogo.svg";
import Card from "../../common/Card";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { PostEntity, PostEntityType } from "neword-core";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { Galleria } from "primereact/galleria";
import { isVideo } from "../../../common/utils/isVideo";

const Wrapper = styled(Card)`
  position: relative;
  padding: 15px;
  width: 100%;
  min-height: 400px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
`;

const AccountName = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: "Helvetica", "Arial", sans-serif;
`;

const PostTime = styled.div`
  font-size: 12px;
  color: #65676b;
  margin-left: 10px;
`;

const ContentWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const SyledGalleria = styled(Galleria)`
  width: 100%;
  padding-top: 52.5%; /* Facebook post image aspect ratio */
  position: relative;
  margin-bottom: 10px;
  min-height: 230px;
`;

const PostImage = styled.img`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; */
  width: 200px;
  height: 200px;
  border-radius: 8px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 99;
`;

function FbPreview<FormDataType extends FieldValues>({
  name,
}: {
  name: Path<FormDataType>;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({ control: methods.control, name: name }); // Ensure posts has a default value

  // UseMemo to calculate imageUrl based on the current posts array and postIndex
  const imageUrl = useMemo(() => {
    return values?.imageUrls || FacebookPreview; // Default to empty string if no image is available
  }, [values]);

  return (
    <Wrapper>
      <TopSection>
        <AccountName>{website?.name}</AccountName>
        <PostTime>Just now</PostTime>
      </TopSection>
      <ContentWrapper>{values && values.message}</ContentWrapper>

      <SyledGalleria
        value={imageUrl.length > 0 ? imageUrl : [FacebookPreview]}
        // style={{ maxWidth: "640px" }}
        // changeItemOnIndicatorHover
        showThumbnails={false}
        showIndicators
        item={(item: string) => {
          return isVideo(item) ? (
            <video
              src={item}
              controls
              style={{ width: "300px", height: "200px" }}
            />
          ) : (
            <PostImage src={item} />
          );
        }}
      />
      <IconWrapper>
        <Logo width="20" height="20" />
      </IconWrapper>
    </Wrapper>
  );
}

export default FbPreview;
