import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/BlueIcon.svg";
import { ReactComponent as WebsiteLogo } from "../../../assets/Icons/WebsiteLogo.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12.21px;
`;

const LeftTopMenu = styled.div`
  align-items: center;
  margin-right: 3px;
`;
const SubjectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const AnswerInputWrapper = styled.div`
  border-radius: 8px;
  background: var(--Card-Background, #f6f9fc);
  padding: 10px 20px;
  display: flex;
  align-items: center;
`;
const BoldText = styled.div`
  text-align: right;
  font-family: "Noto Sans Hebrew";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  min-width: 45px;
  margin: 20px 0px;
`;

const WbsiteUrl = styled.div`
  color: #a4a4a4;
  text-align: right;
  font-family: "Noto Sans Hebrew";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 11px */
  letter-spacing: -0.3px;
`;
const SearchAdBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));

    const wrapperRef = useRef<HTMLDivElement>(null);

    const { getValues, control } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.SEARCH_AD }
    >();

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const value = getValues(`output.${blockIndex}`);
        return value.headlines + "\n" + value.descriptions;
      },
    }));
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          handleBlur(blockIndex);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleBlur]);

    return (
      <Wrapper ref={wrapperRef}>
        <TopSection>
          <WebsiteLogo />
          <LeftTopMenu>
            <AcountText className="ml-1">{website?.name}</AcountText>
            <WbsiteUrl>{website?.domain}</WbsiteUrl>{" "}
          </LeftTopMenu>
        </TopSection>

        <BoldText className="h-full ml-3">
          {" "}
          <EditableTextArea<DocumentEntityType.SEARCH_AD>
            fieldName={`output.${blockIndex}.headlines`}
            blockIndex={blockIndex}
            isEditing={isEditing}
            style={{ color: "#7DACFF" }}
          />
        </BoldText>

        <SubjectWrapper className="mt-3 mb-2">
          <EditableTextArea<DocumentEntityType.SEARCH_AD>
            fieldName={`output.${blockIndex}.descriptions`}
            blockIndex={blockIndex}
            isEditing={isEditing}
            style={{ color: "#9AA8B6" }}
          />
        </SubjectWrapper>
      </Wrapper>
    );
  }
);

export default SearchAdBlock;
