import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { DocumentEntityType, DocumentEntity } from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";

function BlogPostComparisonDescription() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_COMPARISON }
      >
        name="inputParams.blogTopic"
        label="נושא הבלוג פוסט"
        placeholder="בין אילו אפשרויות תרצו להשוות??"
        inputStyle={{ height: 45 }}
      />

      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_COMPARISON }
      >
        name="inputParams.comparisonFocusPoints"
        label="באילו דברים עיקריים תתמקדו בשביל להשוות בין האפשרויות האלה?"
        placeholder="תתארו באילו עקרונות תשתמשו על מנת להשוות בין האפשרויות, תתארו ככל הניתן"
        inputStyle={{ height: 45 }}
      />
    </div>
  );
}

export default BlogPostComparisonDescription;
