import styled from "styled-components";
import {
  Controller,
  FormProvider,
  Path,
  useFormContext,
} from "react-hook-form";
import { useParams } from "react-router";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import Button from "../common/form/Button";
import { useRecoilRefresher_UNSTABLE, useRecoilState } from "recoil";
import {
  documentLoadingState,
  documentState,
  documentsSummariesState,
} from "../../state/documentState";
import documentService from "../../core/services/document.service";
import { InputText } from "primereact/inputtext";
import documentsDictionary from "./documentsDictionary";

import ParamAccordion from "./components/ParamAccordion";
import { Divider } from "primereact/divider";
import { ReactComponent as SparklesIcon } from "../../assets/Icons/Sparkles.svg";
import Accordion from "../common/form/Accordion";

const TextHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #000;
  font-family: "Assistant";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 20.128px */
  letter-spacing: -0.549px;
  margin-top: 20px;
  margin-bottom: 17px;
`;

const Wrapper = styled.div`
  height: 100%;
  /* flex: 1; */
  border-left: 1px solid #e6e6e6;
  width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.div`
  margin-bottom: -0.5rem;
  z-index: 100;
  border-top: 1px solid #e6e6e6;
  position: sticky;
  bottom: -0.5rem;
  width: 100%;
  backdrop-filter: blur(12px);
  background-color: rgba(255, 255, 255, 0.8);
  padding-inline: 30px;
  padding-block: 10px;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  background: var(--main-hebrew, #6300c1);
  width: 170px;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
`;

const ContentWrapper = styled.div`
  padding: 10px;
`;

const NextButton = styled(Button)`
  margin-top: 20px;
  /* margin-left: auto; */
`;

const Skip = styled(Button)`
  margin-top: 20px;
  /* margin-right: auto; */
`;

const FormWrapper: React.FC<{ setDoc: SetStateAction<any> }> = ({ setDoc }) => {
  const { documentId, websiteId } = useParams<{
    documentId: string;
    websiteId: string;
  }>();

  const [documents, setDocuments] = useRecoilState(
    documentsSummariesState(websiteId as string)
  );
  const [isLoading, setIsLoaded] = useRecoilState(documentLoadingState);

  const methods = useFormContext<DocumentEntity>();

  const document = methods.getValues();

  const onGenerate = async () => {
    setIsLoaded(true);
    const params = methods.getValues().inputParams;

    try {
      const doc = await documentService.generateDocument(
        documentId as string,
        websiteId as string,
        params
      );
      methods.setValue("output", doc.output);
      methods.setValue("name", doc.name);
      if (!documents.find((d) => d.id === doc.id)) {
        setDocuments([doc, ...documents]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoaded(false);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0); // Keeps track of the active accordion
  const [transitioning, setTransitioning] = useState(false);
  const [iconsShown, setIconsShown] = useState<boolean[]>(
    new Array(3).fill(false)
  ); // Assuming 3 accordions

  const { form, AdvencedSettings } = documentsDictionary[document.type];

  const handleToggle = (index: number) => {
    // If already open, close it, otherwise open the clicked one
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const handleNext = async (fieldNames: Path<DocumentEntity>[]) => {
    setTransitioning(true);
    const nextIndex = (activeIndex + 1) % form.length;

    if (activeIndex === form.length - 1) {
      // Check if it's the last accordion
      setActiveIndex(-1); // Reset to close the accordion
    } else {
      setActiveIndex(nextIndex);
    }

    // Update iconsShown to show icon for the current and all previous accordions
    const updatedIcons = iconsShown.map(
      (iconShown, i) => iconShown || i === activeIndex
    );
    setIconsShown(updatedIcons);

    setTimeout(() => {
      setTransitioning(false);
    }, 300); // Ensure transitioning is reset after the animation
  };

  useEffect(() => {
    if (transitioning) {
      // Give time for the transition to play out before switching the state
      const timer = setTimeout(() => {
        setTransitioning(false);
      }, 250); // This duration should match your CSS transition time

      return () => clearTimeout(timer);
    }
  }, [transitioning]);

  return (
    <Wrapper className="col-4 scrolli">
      <ContentWrapper className="scrolli2">
        <FormProvider {...methods}>
          <div>
            {form.map((accordion, index) => (
              <ParamAccordion
                key={index}
                title={accordion.title}
                isOpen={!transitioning && activeIndex === index}
                onToggle={() => handleToggle(index)}
                showIcon={iconsShown[index]} // Use updated iconsShown state
              >
                {accordion.content}
                <NextButton
                  primary
                  bgColor="purple"
                  arrowPlacement="right"
                  onClick={() => handleNext(accordion.fieldNames || [])}
                >
                  הבא
                </NextButton>
              </ParamAccordion>
            ))}
          </div>
          <Divider />

          {AdvencedSettings && (
            <Accordion title="הגדרות מתקדמות" icon={<SparklesIcon />}>
              <AdvencedSettings />
            </Accordion>
          )}
        </FormProvider>
      </ContentWrapper>
      <Footer>
        <StyledButton
          className={`step-6-wt ${activeIndex == -1 ? "visible" : ""}`}
          loading={isLoading}
          onClick={methods.handleSubmit(onGenerate)}
          primary
          icon={<SparklesIcon />}
        >
          יצירת תוכן
        </StyledButton>
      </Footer>
    </Wrapper>
  );
};

export default FormWrapper;
