import { useDrop } from "react-dnd";

function DroppableTile({ date, children, onDropEvent }: any) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "POST",
    drop: (item: any) => onDropEvent(item.post, date),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        backgroundColor: isOver ? "rgba(173, 216, 230, 0.5)" : "transparent", // Lighter blue and fully covers tile
        minHeight: "150px", // Ensure min height
        padding: "0px",
        boxSizing: "border-box", // Ensure padding does not cause overflow
        border: isOver ? "2px dashed #00aaff" : "none", // Dashed border when dragging over
        width: "100%", // Ensure full width
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Center content vertically
        alignItems: "center", // Center content horizontally
        transition: "background-color 0.2s ease", // Smooth transition for hover
      }}
    >
      {children}
    </div>
  );
}

export default DroppableTile;
