import { Divider } from "primereact/divider";
import { PostEntity, PostEntityType } from "neword-core";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FieldErrors,
  FieldValues,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import AttachmentInput from "../../common/form/AttachmentInput";
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { useMemo, useEffect, useRef, useState } from "react";
import FbPreview from "../postPreviews/fbPreview";
import CardTitle from "../../common/CardTitle";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import { isVideo } from "../../../common/utils/isVideo";
import { isImage } from "../../../common/utils/isImage";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background: #fcf6f7;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`;

function FacebookPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    control,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const values = useWatch({ control, name: name }); // Ensure posts has a default value of an empty array
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");

  // Function to automatically adjust textarea height
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  // Run auto-resize on mount and when posts change
  useEffect(() => {
    autoResizeTextarea();
  }, [values]);

  return (
    <div className="grid h-full m-0">
      <div className="col-5 p-0">
        <ImageWrapper className="p-4">
          <FbPreview name={name} />
        </ImageWrapper>
      </div>
      <div className="col-7 p-0">
        <div className="media p-2 mt-4">
          <CardTitle title="פרסום פוסט באינסטגרם" />

          <LabelStyled>הוספת מדיה</LabelStyled>
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            validate={(value) => {
              const videos = value.filter((item: string) => isVideo(item));
              const images = value.filter((item: string) => isImage(item));
              let errorText = "";
              if (videos.length > 1) {
                errorText = "מותר לבחור וידאו אחד בלבד";
              }
              if (images.length > 20) {
                errorText = "מותר לבחור עד 20 תמונות בלבד";
              }
              if (videos.length > 0 && images.length > 0) {
                errorText = "לא ניתן לבחור גם תמונה וגם וידאו";
              }

              setImageUrlError(errorText);
              if (errorText === "") {
                return true;
              }

              return errorText;
            }}
          />
          {/* {JSON.stringify(errors)} */}
          <span>{imageUrlError}</span>
        </div>

        <Divider />

        <div className="post p-2 mb-4">
          <LabelStyled>תוכן הפוסט</LabelStyled>
          <InputTextareaStyled
            {...register(`${name}.message`)}
            className="w-90 pl-3"
            placeholder="רשמו משהו, בלה בלה תיאור לפוסט"
            onInput={autoResizeTextarea}
            ref={(e) => {
              register(`${name}.message`).ref(e);
              textareaRef.current = e;
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FacebookPostForm;
