import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { BlockOutputProps } from "./types/blockOutputProps";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/Neword.svg";
import { ReactComponent as InstagramLogo } from "../../../assets/Icons/InstagramIcon.svg";
import InstagramPreview from "../../../assets/images/instagramPreview.png";
import { ReactComponent as InstagramLike } from "../../../assets/Icons/InstagramLike.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const IconsWrapper = styled.div`
  display: none;
  flex-direction: row-reverse;
  margin-top: 10px;
  opacity: 0.5;
  svg {
    width: 55px;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%; /* Full width of the container */
  padding-top: 90%; /* This creates the 1:1 aspect ratio */
  position: relative;
`;

const InstagramPreviewWrapper = styled.img`
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container */
`;

const BottomDiv = styled.div`
  position: absolute;
  bottom: 10px; /* Add a little distance to the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  width: 90%; /* Optional: You can adjust this based on your needs */
  padding: 10px;
  background-color: white; /* Semi-transparent background */
  color: black; /* Optional: Text color */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px; /* Optional: Add some border radius */
`;
const InstagramSponsoredAdBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const website = useRecoilValue(websiteState(websiteId as string));

    const form = useFormContext<
      DocumentEntity & { type: DocumentEntityType.INSTAGRAM_SPONSORED_AD }
    >();
    const wrapperRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const values = form.getValues();
        return values.output[blockIndex].callToAction || "";
      },
    }));

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          handleBlur(blockIndex);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleBlur]);
    return (
      <Wrapper className="col-4" ref={wrapperRef}>
        <TopSection></TopSection>
        <IconWrapper>
          <InstagramLogo />
        </IconWrapper>
        <ImageWrapper>
          <InstagramPreviewWrapper src={InstagramPreview} />
          <BottomDiv>
            <EditableTextArea<DocumentEntityType.INSTAGRAM_SPONSORED_AD>
              fieldName={`output.${blockIndex}.callToAction`}
              isEditing={isEditing}
              blockIndex={blockIndex}
              style={{ fontWeight: 600 }}
            />
          </BottomDiv>
        </ImageWrapper>
        <IconsWrapper>
          <InstagramLike />
        </IconsWrapper>
        <AcountText className="mt-3 flex row-revers">
          {website?.name}
        </AcountText>
        <ContentWrapper className="mt-1">
          <EditableTextArea<DocumentEntityType.INSTAGRAM_SPONSORED_AD>
            fieldName={`output.${blockIndex}.primaryText`}
            isEditing={isEditing}
            blockIndex={blockIndex}
          />
        </ContentWrapper>
      </Wrapper>
    );
  }
);

export default InstagramSponsoredAdBlock;
