import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { ReactComponent as AddIcon } from "../../../assets/Icons/PlusBorder.svg";
import { useRef, useState } from "react";
import { useParams } from "react-router";
import { websiteService } from "../../../core/services/website.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { attachmentsState } from "../../../state/attachmentsState";
import { websiteState } from "../../../state/websitesState";
import { isVideo } from "../../../common/utils/isVideo";

const UploadContainer = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--border-color);
  flex-direction: column;
  cursor: pointer;
  position: relative;
  svg {
    width: 40px;
    height: 40px;
    fill: var(--border-color);
  }
  span {
    font-weight: bold;
    color: var(--border-color);
  }
  &:hover {
    border: 2px dashed var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
    span {
      color: var(--primary-color);
    }
  }
`;

const ImageBox = styled.img`
  cursor: pointer;
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const VideoBox = styled.video`
  cursor: pointer;
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const Wrapper = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 600px;
  max-height: 400px;
  overflow-y: auto;
  justify-content: center;
`;

const HiddenInput = styled.input`
  display: none;
`;

interface Props {
  onHide: () => void;
  onSelectAttachment: (attachmentUrl: string) => void;
  accept: string;
}

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
`;

const SubTitle = styled.h2`
  color: #425466;
  text-align: center;
  font-weight: 300;
`;

const WrapperX = styled.div`
  margin-top: 40px;
`;

const AttachmentsModal: React.FC<Props> = ({
  onHide,
  onSelectAttachment,
  accept,
}) => {
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [existingAttachments, setAttachments] = useRecoilState(
    attachmentsState(websiteId as string)
  );
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const charArray = Array.from(uint8Array).map((byte) =>
        String.fromCharCode(byte)
      );
      const base64String = btoa(charArray.join(""));

      setLoading(true);

      try {
        const attachmentUrl = await websiteService.uploadAttachment(
          websiteId as string,
          file.name,
          base64String
        );
        setAttachments([attachmentUrl, ...existingAttachments]);
        console.log("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      header={
        <WrapperX>
          <Title>התמונות והסרטונים של {website?.name}</Title>
          <SubTitle>בחר או העלה קובץ חדש</SubTitle>
        </WrapperX>
      }
      visible
      onHide={onHide}
      modal
    >
      <br />
      <Grid>
        {loading ? (
          <Wrapper>
            <UploadContainer>
              <span>טוען...</span>
            </UploadContainer>
          </Wrapper>
        ) : (
          <Wrapper>
            <UploadContainer onClick={handleClick}>
              <AddIcon />
            </UploadContainer>
            <HiddenInput
              type="file"
              accept={accept}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Wrapper>
        )}

        {existingAttachments.map((attachment, index) => {
          const isVideoUrl = isVideo(attachment);

          return (
            <Wrapper onClick={() => onSelectAttachment(attachment)} key={index}>
              {isVideoUrl ? (
                <VideoBox src={attachment} controls />
              ) : (
                <ImageBox src={attachment} alt="attachment" />
              )}
            </Wrapper>
          );
        })}
      </Grid>
    </Dialog>
  );
};

export default AttachmentsModal;
