import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import BlogPostSharedSettings from "./advencedSettings/BlogPostSharedSettings";
import BlogPostTipsDescription from "./formComponentes/BlogPostTipsDescription";
import BlogPostComparisonDescription from "./formComponentes/BlogPostComparisonDescription";
import BlogPostCaseStudyDescription from "./formComponentes/BlogPostCaseStudyDescription";
import CartAbandonmentMailDescription from "./formComponentes/CartAbandonmentMailDescription";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

export const welcomEmailAccordions: AccordionProps[] = [
  {
    title: "תיאור כללי לתוכן המייל",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.WELCOME_EMAIL }
        >
          name="inputParams.description"
          label="המסר המרכזי"
          placeholder="מהו המסר המרכזי או ההצעה שאתה רוצה להעביר במייל קבלת הפנים?"
        />

        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.WELCOME_EMAIL }
        >
          name="inputParams.cta"
          label="איזו פעולה תרצה שהנמען יבצע לאחר קריאת המייל?"
          placeholder="לדוגמה ״לינק לפלטפורמה שלנו״"
          hideRephrase
          inputStyle={{ height: 60 }}
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <small className="pr-2 mb-3">* ניתן לבחור יותר מאחד</small>
        <DocumentTone<
          DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
        >
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
