import styled from "styled-components";
import { ReactComponent as AddIcon } from "../../assets/Icons/PlusBorder.svg";
import { useRef, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { websiteState } from "../../state/websitesState";
import { attachmentsState } from "../../state/attachmentsState";
import { websiteService } from "../../core/services/website.service";
import WebsiteDropdownMenu from "../../components/common/form/WebsitesDropdownMenu/WebsiteDropdownMenu";

const UploadImage = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--border-color);
  flex-direction: column;
  transition-duration: 0.05s;
  cursor: pointer;
  position: relative;

  svg {
    width: 40px;
    height: 40px;
    fill: var(--border-color);
  }

  span {
    font-weight: bold;
    color: var(--border-color);
  }

  &:hover {
    border: 2px dashed var(--primary-color);
    svg {
      fill: var(--primary-color);
    }

    span {
      color: var(--primary-color);
    }
  }

  @media (max-width: 768px) {
    height: 100px;
    width: 100px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 480px) {
    height: 80px;
    width: 80px;

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 0.8rem;
    }
  }
`;

const BoxImage = styled.img`
  cursor: pointer;
  box-sizing: border-box;
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);

  &:hover {
    border: 1px solid var(--primary-color);
  }

  @media (max-width: 768px) {
    height: 100px;
    width: 100px;
  }

  @media (max-width: 480px) {
    height: 80px;
    width: 80px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;

  @media (max-width: 480px) {
    margin: 4px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 100%;
  overflow-y: auto;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 12px;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.h2`
  color: #425466;
  text-align: center;
  font-weight: 300;
  font-size: 30px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const WrapperX = styled.div`
  margin-top: 40px;

  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const UploadFilesPage: React.FC = () => {
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [existingAttachments, setAttachments] = useRecoilState(
    attachmentsState(websiteId as string)
  );
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const charArray = Array.from(uint8Array).map((byte) =>
        String.fromCharCode(byte)
      );
      const base64String = btoa(charArray.join(""));

      setLoading(true);

      try {
        const imageUrl = await websiteService.uploadAttachment(
          websiteId as string,
          file.name,
          base64String
        );
        setAttachments([imageUrl, ...existingAttachments]);
        console.log("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <WebsiteDropdownMenu />
      <WrapperX>
        <Title>התמונות של {website?.name}</Title>
        <SubTitle>בחר או העלה תמונה חדשה</SubTitle>
      </WrapperX>
      <br />
      <Grid>
        {loading ? (
          <Wrapper>
            <UploadImage>
              <span>טוען...</span>
            </UploadImage>
          </Wrapper>
        ) : (
          <Wrapper>
            <UploadImage onClick={handleClick}>
              <AddIcon />
            </UploadImage>
            <HiddenInput
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Wrapper>
        )}

        {existingAttachments.map((image, index) => (
          <Wrapper onClick={() => {}} key={index}>
            <BoxImage src={image} alt="attachment" />
          </Wrapper>
        ))}
      </Grid>
    </div>
  );
};

export default UploadFilesPage;
