import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "styled-components";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { ReactComponent as Neword } from "../../../assets/Icons/Neword.svg";
import { ReactComponent as LinkedInLogo } from "../../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as LinkedInRepost } from "../../../assets/Icons/linkedin/LinkedInRepost.svg";

import { ReactComponent as LinkedInComment } from "../../../assets/Icons/linkedin/LinkedInComment.svg"; // Add this
import { ReactComponent as LinkedInShare } from "../../../assets/Icons/linkedin/LinkedInShare.svg"; // Add this
import LinkedInPreview from "../../../assets/images/LinkedInPreview.png";
import { ReactComponent as LinkedInLike } from "../../../assets/Icons/linkedin/LinkedInLike.svg";
import { BlockProps } from "./types/dictionaryProps";
import { RefProps } from "./types/refProps";
import EditableTextArea from "../components/EditableTextArea";
import { Button } from "primereact/button";

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  background: white;
`;

const ButtonCta = styled.button`
  box-sizing: border-box;

  width: 100px;
  height: 30px;

  border: 0.610169px solid #1e6dff;
  border-radius: 3.66102px;
  color: #1e6dff;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 6.712px */
  letter-spacing: -0.183px;
  background-color: white;
`;
const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  justify-content: space-between;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const LeftTopMenu = styled.div`
  display: flex;
  align-items: center;
`;

const LinkedInPreviewWrapper = styled.img`
  border-radius: 6px;
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container */
`;
const BottomDiv = styled.div`
  position: absolute;
  bottom: 10px; /* Add a little distance to the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  width: 90%; /* Optional: You can adjust this based on your needs */
  padding: 10px;
  background-color: white; /* Semi-transparent background */
  color: black; /* Optional: Text color */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 5px; /* Optional: Add some border radius */
`;
const EditableInput = styled.textarea<{ isEditing: boolean }>`
  font-size: 12.21px;
  border: ${({ isEditing }) =>
    isEditing ? "1px solid var(--Input-BorderColor, #e6e6e6)" : "none"};
  background: transparent;
  outline: none;
  width: 100%;
  height: auto;
  font-family: inherit;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  width: 100%; /* Full width of the container */
  padding-top: 90%; /* This creates the 1:1 aspect ratio */
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const LinkedInSponsoredAdBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { websiteId } = useParams();
    const { getValues } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.LINKEDIN_SPONSORED_AD }
    >();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const imageUrl = LinkedInPreview;

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const output = getValues(`output.${blockIndex}`);
        return output.callToActionButton;
      },
    }));
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          handleBlur(blockIndex);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleBlur]);

    return (
      <Wrapper className="col-4" ref={wrapperRef}>
        <TopSection>
          <IconWrapper>
            <LinkedInLogo />
          </IconWrapper>
        </TopSection>

        <ContentWrapper className="">
          <EditableTextArea<DocumentEntityType.LINKEDIN_SPONSORED_AD>
            fieldName={`output.${blockIndex}.captionText`}
            blockIndex={blockIndex}
            isEditing={isEditing}
          />
        </ContentWrapper>
        <ImageWrapper>
          <LinkedInPreviewWrapper src={imageUrl} />
          <BottomDiv
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="  ">
              <EditableTextArea<DocumentEntityType.LINKEDIN_SPONSORED_AD>
                fieldName={`output.${blockIndex}.AdHeadline`}
                isEditing={isEditing}
                blockIndex={blockIndex}
                style={{ fontWeight: 800, fontSize: 12 }}
              />
              <EditableTextArea<DocumentEntityType.LINKEDIN_SPONSORED_AD>
                fieldName={`output.${blockIndex}.AdText`}
                isEditing={isEditing}
                blockIndex={blockIndex}
                style={{ fontWeight: 600, fontSize: 12 }}
              />
            </div>

            <ButtonCta type="button">
              {getValues(`output.${blockIndex}.callToActionButton`)}
            </ButtonCta>
          </BottomDiv>
        </ImageWrapper>

        <IconsWrapper>
          <LinkedInShare />
          <LinkedInRepost />
          <LinkedInComment />
          <LinkedInLike />
          <Neword />
        </IconsWrapper>
      </Wrapper>
    );
  }
);

export default LinkedInSponsoredAdBlock;
