import React, { useEffect, useImperativeHandle, useRef } from "react";
import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import ArticleBlockTextEditor from "../components/ArticleBlockEditor";
import ArticleBlockViewer from "../components/ArticleViewer";
import { BlockProps } from "./types/dictionaryProps";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import { RefProps } from "./types/refProps";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const ArticleBlock = forwardRef<RefProps, BlockProps>(
  ({ isEditing, blockIndex, handleBlur }, ref) => {
    const { getValues } = useFormContext<
      DocumentEntity & { type: DocumentEntityType.ARTICLE }
    >();
    const wrapperRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      onCopyCustom: () => {
        const output = getValues(`output.${blockIndex}`);
        return output.blogHtml;
      },
    }));

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node) &&
          !(event.target as HTMLElement).closest(".tox") // Check if the click was inside TinyMCE editor
        ) {
          handleBlur(blockIndex);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleBlur, blockIndex]);

    return (
      <Wrapper ref={wrapperRef}>
        <ArticleBlockTextEditor blockIndex={blockIndex} />
      </Wrapper>
    );
  }
);

export default ArticleBlock;
