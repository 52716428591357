import {
  Controller,
  Path,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { DocumentEntityType, DocumentEntity } from "neword-core";

import GenericDescriptionInput from "./GenericDescriptionInput";

function BlogPostCaseStudyDescription() {
  return (
    <div className="w-full step-2-wt">
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_CASE_STUDY }
      >
        name="inputParams.description"
        label="תיאור הבעיה"
        placeholder="איזה אתגרים אתם או הלקוח שלכם חוויתם???"
        generateText="צור לי תיאור לבעיה לבלוג פוסט קייס סטאדי"
        reGenerateText="הטקסט הבא הוא תיאור של בעיה, במידה והוא דל, תרחיב אותו קצת, תוסיף עוד תוכן ללא סיכום וללא הקדמה"
      />
      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_CASE_STUDY }
      >
        name="inputParams.solutionDescription"
        label="פתרון הבעיה"
        placeholder="כיצד התגברתם על האתגרים וכיצד פתרתם אותם???"
        generateText="צור לי תיאור לפתרון לבלוג פוסט קייס סטאדי"
        reGenerateText="הטקסט הבא הוא תיאור של פתרון, במידה והוא דל, תרחיב אותו קצת, תוסיף עוד תוכן ללא סיכום וללא הקדמה"
      />

      <GenericDescriptionInput<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_CASE_STUDY }
      >
        name="inputParams.results"
        label="תוצאות?"
        placeholder="לאיזה תוצאות הגעתם? מה הצלחתם להשיג?"
        generateText="צור לי תיאור לתוצאות לבלוג פוסט קייס סטאדי"
        reGenerateText="הטקסט הבא הוא תיאור של תוצאות, במידה והוא דל, תרחיב אותו קצת, תוסיף עוד תוכן ללא סיכום וללא הקדמה"
      />
    </div>
  );
}

export default BlogPostCaseStudyDescription;
