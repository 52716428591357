// ClockSVG.tsx
import React from "react";

interface ClockSVGProps {
  hour: number;
  minute: number;
}

const ClockSVG: React.FC<ClockSVGProps> = ({ hour, minute }) => {
  const isPM = hour >= 12;
  const adjustedHour = hour % 12 || 12;
  const hourAngle = (360 / 12) * (adjustedHour % 12);
  const minuteAngle = (360 / 60) * minute;

  const strokeColor = isPM ? "#fbc531" : "#9b59b6";
  const fillColor = isPM
    ? "rgba(251, 197, 49, 0.1)"
    : "rgba(155, 89, 182, 0.1)";

  return (
    <svg viewBox="0 0 16 16" width="16" height="16">
      <circle
        cx="8"
        cy="8"
        r="7"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="1"
      />
      <line
        stroke={strokeColor}
        strokeWidth="1"
        x1="8"
        y1="8"
        x2={8 + 3 * Math.cos((hourAngle - 90) * (Math.PI / 180))}
        y2={8 + 3 * Math.sin((hourAngle - 90) * (Math.PI / 180))}
      />
      <line
        stroke={strokeColor}
        strokeWidth="1"
        x1="8"
        y1="8"
        x2={8 + 5 * Math.cos((minuteAngle - 90) * (Math.PI / 180))}
        y2={8 + 5 * Math.sin((minuteAngle - 90) * (Math.PI / 180))}
      />
    </svg>
  );
};

export default ClockSVG;
