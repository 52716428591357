// src/components/LocationDropdown.tsx
import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import { useFormContext, Path } from "react-hook-form";
import { ReactComponent as LocationIcon } from "../../../assets/Icons/Location.svg";

const Wrapper = styled.div`
  border-radius: 6px;
  position: relative;
`;

const DropdownButton = styled.div`
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const DropdownList = styled.ul<{ show: boolean }>`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 5px 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
`;

function LocationDropdown({ name }: { name: Path<any> }) {
  const { setValue } = useFormContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const locations = [
    "New York",
    "Los Angeles",
    "Chicago",
    "Houston",
    "Miami",
    "San Francisco",
    "Las Vegas",
    "Seattle",
    "Boston",
    "Austin",
  ];

  const filteredLocations = locations.filter((location) =>
    location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectLocation = (location: string) => {
    setSelectedLocation(location);
    setValue(name, location); // Update form value
    setShowDropdown(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <Wrapper ref={dropdownRef}>
      <DropdownButton onClick={() => setShowDropdown((prev) => !prev)}>
        {selectedLocation || "בחר מיקום"}
        <LocationIcon />
      </DropdownButton>

      <DropdownList show={showDropdown}>
        {showDropdown && (
          <SearchInput
            type="text"
            placeholder="חפש מיקום"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        {filteredLocations.map((location) => (
          <DropdownItem
            key={location}
            onClick={() => handleSelectLocation(location)}
          >
            {location}
          </DropdownItem>
        ))}
      </DropdownList>
    </Wrapper>
  );
}

export default LocationDropdown;
