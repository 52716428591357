import { atom, atomFamily } from "recoil";
import { DocumentEntity } from "neword-core";
import documentService from "../core/services/document.service";

export const documentState = atomFamily<DocumentEntity, string>({
  key: `documentState`,
  default: (documentId: string) => documentService.getDocumentById(documentId),
});

export const documentsSummariesState = atomFamily<
  Omit<DocumentEntity, "output">[],
  string
>({
  key: `documentSummariesState`,
  default: (websiteId: string) =>
    documentService.getDocumentsByWebsiteId(websiteId),
});

export const documentLoadingState = atom({
  key: `documentLoadingState`,
  default: false,
});
