import { DocumentEntityType } from "neword-core";
import InstagramCaptionImg from "../../../assets/images/contentCardsImages/instagram_caption_card_img.png";
import InstagramBio from "../../../assets/images/contentCardsImages/Frame 209-1.png";
import StoryIdea from "../../../assets/images/contentCardsImages/Frame 209-2.png";
import ReelIdea from "../../../assets/images/contentCardsImages/Frame 209-3.png";
import FacebookCaption from "../../../assets/images/contentCardsImages/Frame 209-4.png";
import FacebookPosts from "../../../assets/images/contentCardsImages/Frame 209-5.png";
import FacebookBio from "../../../assets/images/contentCardsImages/Frame 209-6.png";
import LinkedinPost from "../../../assets/images/contentCardsImages/Frame 209-7.png";
import LinkedinPostIdeas from "../../../assets/images/contentCardsImages/Frame 209-8.png";
import LinkedinBio from "../../../assets/images/contentCardsImages/Frame 209-10.png";
import Email from "../../../assets/images/contentCardsImages/Frame 209-11.png";
import Threads from "../../../assets/images/contentCardsImages/Frame 209-20.png";
import SMS from "../../../assets/images/contentCardsImages/Frame 209-30.png";
import Twitter from "../../../assets/images/contentCardsImages/Frame 209-40.png";
import MultiInsta from "../../../assets/images/contentCardsImages/multiinsta.png";
import Keywords from "../../../assets/images/contentCardsImages/Frame 209 (13).png";
import WebAbout from "../../../assets/images/contentCardsImages/Frame11 209-1.png";
import WebRec from "../../../assets/images/contentCardsImages/Frame11 209-2.png";
import ProdAbout from "../../../assets/images/contentCardsImages/Frame11 209-3.png";
import Faq from "../../../assets/images/contentCardsImages/Frame11 209.png";
import Blogpost_opinion from "../../../assets/images/contentCardsImages/blogpost_opinion.png";
import Blogpost_tips from "../../../assets/images/contentCardsImages/blogpost_tips.png";
import Blogpost_case from "../../../assets/images/contentCardsImages/blogpost_case.png";
import Blogpost_vs from "../../../assets/images/contentCardsImages/blogpost_vs.png";
import Blogpost_intro from "../../../assets/images/contentCardsImages/blogpost_intro.png";
import Blogpost_post from "../../../assets/images/contentCardsImages/blogpost_post.png";
import Serachengine from "../../../assets/images/contentCardsImages/searchengine.png";
import LinkedinAd from "../../../assets/images/contentCardsImages/LinkedinAd.png";
import InstaAd from "../../../assets/images/contentCardsImages/insta_ad.png";
import { ContentCardType } from "../../website/overview/contentCards/cardTypes";

const DocBoxes: {
  type: DocumentEntityType;
  title: string;
  image: string;
  description: string;
  platformType: ContentCardType;
}[] = [
  {
    type: DocumentEntityType.INSTAGRAM_BIO_DESCRIPTION,
    title: "ביו לאינסטגרם",
    image: InstagramBio,
    description:
      "יצירת תיאור קצר וקולע לפרופיל האינסטגרם שלך, שימשוך עוקבים חדשים.",
    platformType: ContentCardType.INSTAGRAM,
  },
  {
    type: DocumentEntityType.INSTAGRAM_CAPTION,
    title: "קפשן לאינסטגרם",
    image: InstagramCaptionImg,
    description:
      "יצירת טקסט מושך לפוסטים באינסטגרם, שיגרום לאנשים לעצור ולהגיב.",
    platformType: ContentCardType.INSTAGRAM,
  },
  {
    type: DocumentEntityType.INSTAGRAM_POST_IDEA,
    title: "רעיון לפוסט באינסטגרם",
    image: MultiInsta,
    description:
      "הצעת נושאים ורעיונות לפוסטים שיתאימו לקהל היעד שלך ויעוררו עניין.",
    platformType: ContentCardType.INSTAGRAM,
  },
  {
    type: DocumentEntityType.INSTAGRAM_REEL_IDEA,
    title: "רעיון לריל",
    image: ReelIdea,
    description:
      "רעיונות לתוכן ויזואלי מושך שיתאים לפורמט הקצר והדינמי של ריל.",
    platformType: ContentCardType.INSTAGRAM,
  },
  {
    type: DocumentEntityType.INSTAGRAM_STORY_IDEA,
    title: "רעיון לסטורי",
    image: StoryIdea,
    description: "הצעות יצירתיות לשיתוף תוכן יומיומי שיעניין את העוקבים שלך.",
    platformType: ContentCardType.INSTAGRAM,
  },
  {
    type: DocumentEntityType.FACEBOOK_PAGE_ABOUT,
    title: "אודות לדף פייסבוק",
    image: FacebookBio,
    description: "יצירת טקסט שמסביר בבהירות על העסק או המותג שלך ומעודד מעקב.",
    platformType: ContentCardType.FACEBOOK,
  },
  {
    type: DocumentEntityType.FACEBOOK_POST_CAPTION,
    title: "טקסט לפוסט בפייסבוק",
    image: FacebookCaption,
    description: "יצירת תוכן שיעורר שיחה ויגביר את החשיפה של הפוסטים שלך.",
    platformType: ContentCardType.FACEBOOK,
  },
  {
    type: DocumentEntityType.FACEBOOK_POST_IDEA,
    title: "רעיון לפוסט בפייסבוק",
    image: FacebookPosts,
    description:
      "הצעת נושאים ורעיונות לפוסטים שיתאימו לקהל שלך ויעודדו אינטראקציה.",
    platformType: ContentCardType.FACEBOOK,
  },
  {
    type: DocumentEntityType.GOOGLE_ADS_KEYWORDS,
    title: "מילות מפתח לגוגל אדס",
    image: Keywords,
    description: "איתור מילות מפתח שיביאו תנועה איכותית לאתר או לעסק שלך.",
    platformType: ContentCardType.GOOGLE,
  },
  {
    type: DocumentEntityType.LINKEDIN_PAGE_ABOUT,
    title: "אודות לעמוד לינקדאין",
    image: LinkedinBio,
    description: "יצירת טקסט שמשקף את הערכים והיתרונות של החברה שלך.",
    platformType: ContentCardType.LINKEDIN,
  },
  {
    type: DocumentEntityType.LINKEDIN_POST_CAPTION,
    title: "טקסט לפוסט בלינקדאין",
    image: LinkedinPost,
    description:
      "יצירת תוכן שיעניין אנשי מקצוע ויחזק את המיתוג האישי או העסקי שלך.",
    platformType: ContentCardType.LINKEDIN,
  },
  {
    type: DocumentEntityType.LINKEDIN_POST_IDEA,
    title: "רעיון לפוסט בלינקדאין",
    image: LinkedinPostIdeas,
    description: "הצעת נושאים ורעיונות לפוסטים מקצועיים שיעוררו דיון מעניין.",
    platformType: ContentCardType.LINKEDIN,
  },
  {
    type: DocumentEntityType.SMS_CONTENT,
    title: "תוכן ל-SMS שיווקי",
    image: SMS,
    description:
      "יצירת מסר קצר ואפקטיבי שיניע את הלקוחות לפעולה בתוך מגבלת התווים.",
    platformType: ContentCardType.SMS,
  },
  {
    type: DocumentEntityType.FLASH_SALE_SMS,
    title: "תוכן לסמס מבצע בזק שיווקי",
    image: SMS,
    description:
      "יצירת מסר קצר ואפקטיבי שיניע את הלקוחות לפעולה בתוך מגבלת התווים.",
    platformType: ContentCardType.SMS,
  },
  {
    type: DocumentEntityType.NOTIFICATION_SMS,
    title: "תוכן לסמס התראה",
    image: SMS,
    description:
      "יצירת מסר קצר ואפקטיבי שיניע את הלקוחות לפעולה בתוך מגבלת התווים.",
    platformType: ContentCardType.SMS,
  },
  {
    type: DocumentEntityType.PUSH_NOTIFICATION,
    title: "הודעת פוש",
    image: SMS,
    description:
      "יצירת מסר קצר ואפקטיבי שיניע את הלקוחות לפעולה בתוך מגבלת התווים.",
    platformType: ContentCardType.SMS,
  },
  {
    type: DocumentEntityType.THREADS_TWIT,
    title: "פוסט לת'רדס",
    image: Threads,
    description:
      "יצירת תוכן שיתאים לאופי של הפלטפורמה ויעורר עניין בקרב המשתמשים.",
    platformType: ContentCardType.TWITTER,
  },
  {
    type: DocumentEntityType.TWITTER_TWIT,
    title: "ציוץ לטוויטר",
    image: Twitter,
    description: "יצירת מסר קצר וקולע שיגרום לאנשים לרצות לשתף ולהגיב.",
    platformType: ContentCardType.THREADS,
  },
  {
    type: DocumentEntityType.WEBSITE_ABOUT,
    title: "עמוד אודות לאתר",
    image: WebAbout,
    description: "יצירת תוכן שמספר את הסיפור של העסק או המותג שלך בצורה מושכת.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.WEBSITE_FAQ,
    title: "שאלות נפוצות לאתר",
    image: Faq,
    description:
      "ניסוח שאלות ותשובות שמספקות מידע חשוב ללקוחות וחוסכות זמן לצוות.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.WEBSITE_PRODUCT_DESCRIPTION,
    title: "תיאור מוצר לאתר",
    image: ProdAbout,
    description: "יצירת תוכן שמדגיש את היתרונות של המוצרים ומעודד רכישה.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.WEBSITE_TESTEMONIAL,
    title: "המלצה לאתר",
    image: WebRec,
    description:
      "ניסוח המלצות אמינות שמדגישות את היתרונות של המוצר או השירות שלך.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.ARTICLE,
    title: "מאמר לאתר",
    image: Blogpost_post,
    description: "יצירת מאמר איכותי שמגדיל את החשיפה לאתר שלך.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.BLOG_POST_OPINION,
    title: "בלוג פוסט דעה",
    image: Blogpost_opinion,
    description: "יצירת מאמר איכותי שמגדיל את החשיפה לאתר שלך.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.BLOG_POST_TIPS,
    title: "בלוג פוסט טיפים",
    image: Blogpost_tips,
    description: "יצירת מאמר איכותי שמגדיל את החשיפה לאתר שלך.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.BLOG_POST_GUIDE,
    title: "בלוג פוסט הדרכתי",
    image: Blogpost_intro,
    description: "יצירת מאמר איכותי שמגדיל את החשיפה לאתר שלך.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.BLOG_POST_COMPARISON,
    title: "בלוג פוסט השוואה",
    image: Blogpost_vs,
    description: "יצירת מאמר איכותי שמגדיל את החשיפה לאתר שלך.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.BLOG_POST_CASE_STUDY,
    title: "בלוג פוסט קייס סטאדי",
    image: Blogpost_case,
    description: "יצירת מאמר איכותי שמגדיל את החשיפה לאתר שלך.",
    platformType: ContentCardType.WEB,
  },
  {
    type: DocumentEntityType.EMAIL_CONTENT,
    title: "תוכן למייל שיווקי",
    image: Email,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.EMAIL,
  },
  {
    type: DocumentEntityType.CART_ABANDONMENT_EMAIL,
    title: "תוכן למייל נטישת עגלה",
    image: Email,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.EMAIL,
  },
  {
    type: DocumentEntityType.TRAFFIC_EMAIL,
    title: "תוכן למייל טראפיק",
    image: Email,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.EMAIL,
  },
  {
    type: DocumentEntityType.NEWSLETTER_EMAIL,
    title: "תוכן למייל ניוזלטר",
    image: Email,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.EMAIL,
  },
  {
    type: DocumentEntityType.PRODUCT_UPDATE_EMAIL,
    title: "תוכן למייל עדכוני מוצר",
    image: Email,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.EMAIL,
  },
  {
    type: DocumentEntityType.WELCOME_EMAIL,
    title: "תוכן למייל ברוך הבא",
    image: Email,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.EMAIL,
  },
  {
    type: DocumentEntityType.INSTAGRAM_SPONSORED_AD,
    title: "פרסומת לאינסטגרם",
    image: InstaAd,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.INSTAGRAM,
  },
  {
    type: DocumentEntityType.LINKEDIN_SPONSORED_AD,
    title: "פרסומת ללינקדאין",
    image: LinkedinAd,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.LINKEDIN,
  },

  {
    type: DocumentEntityType.FACEBOOK_SPONSORED_AD,
    title: "פרסומת לפייסבוק",
    image: FacebookCaption,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.FACEBOOK,
  },
  {
    type: DocumentEntityType.SEARCH_AD,
    title: " פרסומת למנוע חיפוש",
    image: Serachengine,
    description:
      "יצירת הודעות שמושכות את תשומת הלב של הנמענים ומובילות לפעולה.",
    platformType: ContentCardType.GOOGLE,
  },
];

export default DocBoxes;
