import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "../modals/ModalHeader";
import { InputTextarea } from "primereact/inputtextarea";
import { ContentCardIcons } from "../website/overview/contentCards/iconMappings";
import { Divider } from "primereact/divider";
import { useCallback, useMemo, useRef, useState } from "react";
import Button from "../common/form/Button";
import AddPlatformDropDown from "../AddPlatformDropDown";
import IgPreview from "./postPreviews/IgPreview";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import ClockSVG from "../common/ClockSVG";
import { addLocale } from "primereact/api";
import { PostEntity, PostEntityType } from "neword-core";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useController,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { postTypeToForm } from "./forms/postTypeToForm";
import postService from "../../core/services/post.service";
import { useParams } from "react-router";
import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";
import { ReactComponent as RemoveIcon } from "../../assets/Icons/Remove.svg";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { PostCardIcons } from "./postIconMappings";
import { ZPostData } from "neword-core/dist/cjs/entities/post/postData";
import IconButton from "../common/IconButton";
import { createPostDeafultParams } from "./creatPostDeafultParams";
import { GridFilterInputMultipleSingleSelectProps } from "@mui/x-data-grid";
import { useRecoilState } from "recoil";
import { postsSummariesState } from "../../state/postState";
import DateInput from "./PostDateInput";
import PostDateInput from "./PostDateInput";

const DialogStyled = styled(Dialog)`
  .p-dialog-content {
    padding: 0px;
    overflow: visible;
  }

  .p-dialog-header {
    padding: 0px !important;
  }

  .p-dialog-footer {
    padding: 1.5em !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

interface Props {
  postDefaults?: PostEntity["data"];
  scheduleDate?: Date;
  onHide: () => void;
}

const IconWrapper = styled.div<{ isEditing: boolean }>`
  /* border: ${({ isEditing }) => (!isEditing ? "none" : "solid 2px  black")};

  border-radius: 8.5px; */
`;
const PlatformsWrapper = styled.div`
  border-right: 1px solid var(--border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
`;

const RemoveSocial = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--title-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
`;

const PlatformIconWrapper = styled.div<{
  backgroundColor?: string;
  active?: boolean;
  dashed?: boolean;
  iconColor?: string;
}>`
  &:hover ${RemoveSocial} {
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    border-color: var(--primary-purple);

    svg path {
      fill: ${(props) =>
        props.iconColor ? "var(--primary-purple)" : "white !important"};
    }
  }
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: ${(props) =>
    props.active ? props.backgroundColor : "var(--border-color)"};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dashed ? "1px dashed lightgrey" : "none")};
  svg {
    width: 15px;
    height: 15px;
    fill: ${(props) =>
      props.iconColor ? props.iconColor : "white !important"};

    path {
      fill: ${(props) =>
        props.iconColor ? props.iconColor : "white !important"};
    }
  }
`;

const FormWrapper = styled.div`
  flex: 1;
`;

const SocialWrapper = styled.div`
  width: 80px;
`;

const AddSocialButton = styled.div`
  cursor: pointer;

  &:hover {
    background: var(--light-bg);
  }
`;

const PublishPostModal: React.FC<Props> = ({
  onHide,
  postDefaults,
  scheduleDate,
}) => {
  const addSocialMenu = useRef<Menu>(null);
  const { websiteId } = useParams();

  const [postsSummaries, setPostsSummaries] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  const methods = useForm<
    Omit<PostEntity, "data"> & { posts: PostEntity["data"][] }
  >({
    defaultValues: {
      scheduleDate: scheduleDate,
      type:
        (postDefaults?.type as PostEntityType) || PostEntityType.FACEBOOK_POST,
      posts: [
        postDefaults || createPostDeafultParams(PostEntityType.FACEBOOK_POST),
      ],
    },
  });

  methods.getValues("scheduleDate");
  const posts = useFieldArray({ control: methods.control, name: "posts" });

  const [postType, setPostType] = useState(
    (postDefaults?.type as PostEntityType) || PostEntityType.FACEBOOK_POST
  );

  const getTypeIndex = useCallback(
    (type: PostEntityType) =>
      posts.fields.findIndex((post) => post.type === type),
    [posts]
  );

  const postIndex = useMemo(
    () => getTypeIndex(postType),
    [posts.fields, postType]
  );

  const FormComponent =
    postTypeToForm[methods.getValues(`posts.${postIndex}.type`)];

  const handleSubmit: SubmitHandler<
    Omit<PostEntity, "data"> & { posts: PostEntity["data"][] }
  > = async (values) => {
    const { posts, ...restValues } = values;
    const postsToSubmit: PostEntity[] = values.posts.map((p) => ({
      ...restValues,

      data: { ...p },
      type: p.type as PostEntityType,
    }));
    await postService.createDocument(postsToSubmit, websiteId as string);
    setPostsSummaries(
      await postService.getPostsByWebsiteId(websiteId as string)
    );
    onHide();
  };

  const onClickIcon = (type: PostEntityType) => {
    if (getTypeIndex(type) < 0) {
      const deafultValues = createPostDeafultParams(type);

      const newPost: PostEntity["data"] = {
        ...deafultValues,
        message: posts.fields[postIndex].message,
        imageUrls: posts.fields[postIndex].imageUrls,
      };

      posts.append(newPost);
    }
    setPostType(type);
  };

  let menuitems = Object.entries(PostCardIcons)
    .filter(
      ([key, value]) =>
        !!value.iconPlain && getTypeIndex(key as PostEntityType) < 0
    )
    .map(([key, value]) => ({
      template: () => (
        <AddSocialButton
          onClick={() => onClickIcon(key as PostEntityType)}
          className="p-3 flex gap-10  align-items-center"
        >
          <PlatformIconWrapper backgroundColor={value.color} active>
            {value.iconPlain && <value.iconPlain />}
          </PlatformIconWrapper>
          {value.socialPostingText && value.socialPostingText}
        </AddSocialButton>
      ),
    }));

  const onRemovePost = (index: number) => {
    if (posts.fields.length === 1) {
      onHide();
      return;
    }
    let typeToMove = posts.fields[postIndex].type;

    if (index === postIndex) {
      const newPostIndex = posts.fields.findIndex((_, i) => i !== index);
      typeToMove = posts.fields[newPostIndex].type;
    }

    posts.remove(index);

    setPostType(typeToMove as PostEntityType);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <DialogStyled
          header={<ModalHeader OnClose={onHide} />}
          closable={false}
          visible
          position={"center"}
          style={{
            width: "60vw",
            margin: "0",
            boxShadow: "none",

            borderLeft: "solid 1px var(--border-color)",
            borderRadius: "0px",
            maxHeight: "100%",
            padding: "0",
          }}
          onHide={onHide}
          draggable={false}
          resizable={false}
          footer={
            <div className="grid m-0">
              <div className="col-9">
                <Button
                  onClick={methods.handleSubmit(handleSubmit)}
                  bgColor="purple"
                  primary
                  className="mb-0"
                >
                  תזמון פוסט
                </Button>
              </div>
              <div className="col-3">
                <PostDateInput />
              </div>
            </div>
          }
        >
          <div className="flex m-0">
            <Tooltip target=".remove-icon" />
            <Menu model={menuitems} popup ref={addSocialMenu} id="socialmenu" />
            <Wrapper>
              {FormComponent ? (
                <FormComponent name={`posts.${postIndex}`} index={postIndex} />
              ) : (
                <div>no form</div>
              )}{" "}
            </Wrapper>
            <SocialWrapper>
              <PlatformsWrapper>
                {Object.entries(PostCardIcons)
                  .filter(
                    ([key, value]) =>
                      !!value.iconPlain &&
                      getTypeIndex(key as PostEntityType) > -1
                  ) // Filter out items that don't have an iconPlain
                  .map(([key, value]) => {
                    const isActive = getTypeIndex(key as PostEntityType);

                    return (
                      <IconWrapper isEditing={isActive === postIndex}>
                        <PlatformIconWrapper
                          onClick={() => onClickIcon(key as PostEntityType)}
                          active={isActive === postIndex}
                          backgroundColor={value.color}
                        >
                          {isActive > -1 && (
                            <RemoveSocial
                              onClick={() => onRemovePost(isActive)}
                              className="remove-icon"
                              data-pr-tooltip="הסר פלטפורמה מפרסום"
                              data-pr-position="right"
                            >
                              <RemoveIcon />
                            </RemoveSocial>
                          )}

                          <div>
                            <IconButton
                              icon={value.iconPlain && <value.iconPlain />}
                            />
                          </div>
                        </PlatformIconWrapper>
                      </IconWrapper>
                    );
                  })}

                <PlatformIconWrapper
                  backgroundColor="white"
                  active
                  dashed
                  iconColor="lightgrey"
                  onClick={(event) => addSocialMenu.current?.toggle(event)}
                  aria-controls="socialmenu"
                  aria-haspopup
                >
                  <PlusIcon />
                </PlatformIconWrapper>
              </PlatformsWrapper>
            </SocialWrapper>
          </div>
        </DialogStyled>
      </form>
    </FormProvider>
  );
};

export default PublishPostModal;
