import styled from "styled-components";
import { ReactComponent as AddIcon } from "../../../assets/Icons/Plus.svg";
import AttachmentsModal from "./AttachmentsModal";
import { useState } from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import { ReactComponent as TrashIcon } from "../../../assets/Icons/Trash.svg";
import { Theme } from "../../../core/theme/theme";
import { isVideo } from "../../../common/utils/isVideo";

const UploadImage = styled.div<{ backgroundImage: string }>`
  position: relative;
  height: ${(props) => (props.backgroundImage ? "100px" : "auto")};
  width: ${(props) => (props.backgroundImage ? "100px" : "auto")};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  transition-duration: 0.05s;

  svg {
    width: 10px;
    height: 10px;
    fill: var(--title-color);
  }
  span {
    font-size: 12px;
    font-weight: bold;
    color: var(--title-color);
  }
  &:hover {
    svg {
      fill: ${Theme.colors.purple};
    }
    span {
      color: ${Theme.colors.purple};
    }
  }
  &.p-invalid {
    border-color: red;
  }
`;

const TrashIconStyled = styled(TrashIcon)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  fill: red !important;
  position: absolute;
  z-index: 10;
`;

const TrashIconWrapper = styled.div`
  position: absolute;
  top: 5.5px;
  right: 5.5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: var(--light-bg);
  transition-duration: 0.05s;
  z-index: 10;
  :hover {
    background: red;

    ${TrashIconStyled} {
      fill: white !important;
    }
  }
`;

function AttachmentInput<FormDataType extends FieldValues>({
  name,
  validate,
}: {
  name: Path<FormDataType>;
  validate?: () => boolean | string;
}) {
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);

  const field = useController({
    name,
    rules: {
      validate: validate,
    },
  });

  return (
    <div className="flex">
      <UploadImage
        backgroundImage={field.field.value || ""}
        onClick={() => setShowAttachmentsModal(true)}
        className={field.fieldState.error ? "p-invalid" : ""}
      >
        {!field.field.value && (
          <>
            <AddIcon />
            <span>הוספת תמונה</span>
          </>
        )}

        {field.field.value && isVideo(field.field.value) ? (
          <video
            src={field.field.value}
            controls
            style={{ width: "100px", height: "100px", borderRadius: "8px" }}
          />
        ) : field.field.value ? (
          <img
            src={field.field.value}
            alt="Attachment Preview"
            style={{ width: "100px", height: "100px", borderRadius: "8px" }}
          />
        ) : null}

        {field.field.value && (
          <TrashIconWrapper
            onClick={(e) => {
              e.stopPropagation(); // Prevent the modal from opening
              field.field.onChange("");
            }}
          >
            <TrashIconStyled className="trash" />
          </TrashIconWrapper>
        )}
      </UploadImage>
      {showAttachmentsModal && (
        <AttachmentsModal
          onSelectAttachment={(img) => {
            field.field.onChange(img);
            setShowAttachmentsModal(false);
          }}
          accept={".png,.jpeg,.jpg,.webp"}
          onHide={() => setShowAttachmentsModal(false)}
        />
      )}
    </div>
  );
}

export default AttachmentInput;
