import styled from "styled-components";
import Avatar, { genConfig } from "react-nice-avatar";
import { FieldValues, Path } from "react-hook-form";
import RadioGroup from "../../common/form/RadioGroup";
import Badge from "../../common/Badge";
import { ReactComponent as ToneYellow } from "../../../assets/Icons/tone/ToneYellow.svg";
import { ReactComponent as ToneRed } from "../../../assets/Icons/tone/ToneRed.svg";
import { ReactComponent as ToneBlue } from "../../../assets/Icons/tone/ToneBlue.svg";
import { ReactComponent as ToneDark } from "../../../assets/Icons/tone/ToneDark.svg";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as TonePurpel } from "../../../assets/Icons/tone/TonePurpel.svg";
import { DocumentToneType } from "neword-core";
import { ReactComponent as InfoIcon } from "../../../assets/Icons/Info.svg";

interface Props<T> {
  threeColumn?: boolean;
  fieldName: Path<T>;
}

const TooltipTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    font-weight: 600;
    color: var(--title-color);
  }
`;

const InsideRadio = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;

  .infoicon {
    width: 14px;
    height: 14px;
    right: -27px;
    top: 0;
    path {
      fill: #bdc7ca !important;
    }
  }
`;

const RadioWrapper = styled.div`
  .singleradio {
    background: white;
  }
  span {
    color: var(--title-color);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const AvatarHairThick = styled(Avatar)`
  svg:nth-child(2) {
    path {
    }
    left: 0;
  }

  svg:nth-child(4) {
    path {
    }
    left: 0;
  }
`;

const BadgeWrapper = styled.div`
  z-index: 22;
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -50%);
`;

const TooltipStyled = styled(Tooltip)``;

function DocumentTone<FormDataType extends FieldValues>(
  props: Props<FormDataType>
) {
  return (
    <>
      <Wrapper>
        <RadioWrapper>
          <RadioGroup<FormDataType, DocumentToneType>
            ColumnNumber={"2"}
            fieldName={props.fieldName}
            options={[
              {
                value: "MARKETING",
                render: (
                  <>
                    <TooltipStyled target=".infoicon.storytelling">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneYellow
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                          <span>
                            סטוריטלינג | סגנון שמבוסס על נרטיב, כלומר מספר סיפור
                            כדי להעביר מסר או לקח. זהו סגנון מרתק ולעיתים קרובות
                            גם רגשי.
                          </span>
                        </TooltipTitle>
                        <small>
                          דוגמה: "דמיין שאתה מתעורר בוקר אחד ומגלה שתנועת
                          הגולשים באתר שלך הכפילה את עצמה בלילה. זה בדיוק מה
                          שקרה לי כשיישמתי את הטיפים הבאים..."
                        </small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneYellow
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <span>סטוריטלינג</span>
                      <InfoIcon
                        className="infoicon storytelling"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                value: "SOLDABLE",
                render: (
                  <>
                    <TooltipStyled target=".infoicon.soldable">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneRed
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                          <span>
                            מבוסס-מידע | סגנון שמסתמך על נתונים, עובדות או מחקר
                            כדי להעביר מסר. זהו סגנון מובן ומעורר אמון.
                          </span>
                        </TooltipTitle>
                        <small>
                          דוגמה: "הנתונים מראים ש-90% מהמשתמשים מעדיפים את המוצר
                          שלנו על פני המתחרים."
                        </small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneRed style={{ width: "1.5rem", height: "1.5rem" }} />
                      <span>מבוסס-מידע</span>
                      <InfoIcon
                        className="infoicon soldable"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                value: "INSPIRED",
                render: (
                  <>
                    <TooltipStyled target=".infoicon.inspired">
                      <div className="p-4">
                        <TooltipTitle>
                          <TonePurpel
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                          <span>
                            השראתי | סגנון שמעורר השראה ויצירתיות. זהו סגנון
                            שמוביל את הקוראים לחשוב בצורה חדשה או לראות את
                            הדברים באור אחר.
                          </span>
                        </TooltipTitle>
                        <small>
                          דוגמה: "החיים הם יצירה מתמשכת – כל יום מביא איתו
                          אתגרים חדשים ואפשרויות צמיחה."
                        </small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <TonePurpel
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                      <span>השראתי</span>
                      <InfoIcon
                        className="infoicon inspired"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                value: "INSTRUCTIONAL",
                render: (
                  <>
                    <TooltipStyled target=".infoicon.instructional">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneBlue
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                          <span>
                            הדרכתי | סגנון שמסביר כיצד לבצע משימות או להשיג
                            מטרות. זהו סגנון פרקטי שמכוון לתוצאות מעשיות.
                          </span>
                        </TooltipTitle>
                        <small>
                          דוגמה: "למד כיצד ליצור פרופיל מקצועי מרהיב בלינקדאין
                          תוך מספר שלבים פשוטים."
                        </small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneBlue style={{ width: "1.5rem", height: "1.5rem" }} />
                      <span>הדרכתי</span>
                      <InfoIcon
                        className="infoicon instructional"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
              {
                value: "CONVINCING",
                render: (
                  <>
                    <TooltipStyled target=".infoicon.convincing">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneDark
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                          <span>
                            משכנע | סגנון שמטרתו לשכנע את הקוראים או להניע אותם
                            לפעולה. זהו סגנון שנועד ליצור השפעה על הקורא.
                          </span>
                        </TooltipTitle>
                        <small>
                          דוגמה: "האם אתם מוכנים לשדרג את העסק שלכם ולהיות חלק
                          מההצלחה הבאה? בואו לגלות איך ניתן להתחיל היום."
                        </small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneDark style={{ width: "1.5rem", height: "1.5rem" }} />
                      <span>משכנע</span>
                      <InfoIcon
                        className="infoicon convincing"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },

              {
                value: "HOMORISTIC",
                render: (
                  <>
                    <TooltipStyled target=".infoicon.convincing">
                      <div className="p-4">
                        <TooltipTitle>
                          <ToneDark
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                          <span>
                            הומוריסטי | סגנון שמטרתו למשוך את צומת ליבו של הקורא
                            תוך שימוש בהומור קל.
                          </span>
                        </TooltipTitle>
                        <small>
                          דוגמה: "שימו חגורות בטיחות, העסק שלכם הולך לטוס
                          גבוהה!"
                        </small>
                      </div>
                    </TooltipStyled>
                    <InsideRadio className="insideradio">
                      <ToneDark style={{ width: "1.5rem", height: "1.5rem" }} />
                      <span>הומוריסטי</span>
                      <InfoIcon
                        className="infoicon convincing"
                        data-pr-position="top"
                      />
                    </InsideRadio>
                  </>
                ),
              },
            ]}
          />
        </RadioWrapper>
      </Wrapper>
    </>
  );
}

export default DocumentTone;
