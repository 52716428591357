import { ReactComponent as IgIcon } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as Facebook } from "../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as Linkedin } from "../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as Twitter } from "../../assets/Icons/XLogo.svg";
import { ReactComponent as Threads } from "../../assets/Icons/ThreadsLogo.svg";
import { ReactComponent as Email } from "../../assets/Icons/EmailLogo.svg";
import { ReactComponent as Sms } from "../../assets/Icons/SmsIcon.svg";
import { ReactComponent as WebsiteLogo } from "../../assets/Icons/WebsiteLogo.svg";
import { ReactComponent as Google } from "../../assets/Icons/ColoredGoogle.svg";
import { ReactComponent as FacebookFlat } from "../../assets/Icons/FacebookIconFlat.svg";
import { ReactComponent as EmailFlat } from "../../assets/Icons/EmailIconFlat.svg";
import { ReactComponent as LinkedinFlat } from "../../assets/Icons/LinkedinIconFlat.svg";
import { ReactComponent as IgIconPlain } from "../../assets/Icons/PlainSocialIcons/Instagram.svg";
import { ReactComponent as FacebookPlain } from "../../assets/Icons/PlainSocialIcons/Facebook.svg";

import styled from "styled-components";
import { PostEntityType } from "neword-core";
import { ReactComponent as LinkedinPlain } from "../../assets/Icons/PlainSocialIcons/Linkedin.svg";

const GoogleStyled = styled(Google)`
  width: 20px;
  height: 20px;
`;

// Define a type for the icons and their corresponding colors
type ContentCardIcon = {
  icon: React.ReactElement;
  color: string;
  flatIcon?: React.ComponentType<any>;
  iconPlain?: React.ComponentType<any>;
  socialPostingText?: string;
};

// Map the types to their corresponding icon components and colors
export const PostCardIcons: Record<PostEntityType, ContentCardIcon> = {
  [PostEntityType.INSTAGRAM_POST]: {
    icon: <IgIcon />,
    color: "#e1306c", // Instagram's official gradient pink color
    iconPlain: IgIconPlain,
    socialPostingText: "פרסם באינסטגרם",
  },
  [PostEntityType.FACEBOOK_POST]: {
    icon: <Facebook />,
    color: "#1877F2", // Facebook's official blue color
    flatIcon: FacebookFlat,
    iconPlain: FacebookPlain,
    socialPostingText: "פרסם בפייסבוק",
  },
  [PostEntityType.TWITTER_TWIT]: {
    icon: <Twitter />,
    color: "black", // Twitter's official blue color
  },
  [PostEntityType.LINKEDIN_POST]: {
    icon: <Linkedin />,
    color: "#0077B5", // LinkedIn's official blue color
    flatIcon: LinkedinFlat,
    iconPlain: LinkedinPlain,
    socialPostingText: "פרסם בלינקדאין",
  },
  [PostEntityType.THREADS_TWIT]: {
    icon: <Threads />,
    color: "#000000", // Threads' official black color
  },
  [PostEntityType.EMAIL]: {
    icon: <Email />,
    color: "#D44638", // Gmail's official red color
    flatIcon: EmailFlat,
  },
  [PostEntityType.SMS]: {
    icon: <Sms />,
    color: "#4CAF50", // SMS/Android Messages green color
  },
  [PostEntityType.WEBSITE]: {
    icon: <WebsiteLogo />,
    color: "#F7931E", // Generic web orange color
  },
  [PostEntityType.TIKTOK_POST]: {
    icon: <WebsiteLogo />,
    color: "#F7931E", // Generic web orange color
  },
  [PostEntityType.ARTICLE]: {
    icon: <WebsiteLogo />,
    color: "#F7931E", // Generic web orange color
  },
  // [PostEntityType]: {
  //   icon: GoogleStyled,
  //   color: "#4285F4", // Google blue color
  // },
} as const;
