import { InputText } from "primereact/inputtext";
import React, { useCallback, useState } from "react";
import FormStyle from "../../../common/form/FormStyle";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";
import { ReactComponent as AddIcon } from "../../../../assets/Icons/PlusBorder.svg";
import { Controller, useFormContext } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import RegexValidations from "../../../../core/validation/regexValidations";
import AttachmentInput from "../../../common/form/AttachmentInput";
import crollerService from "../../../../core/services/croller.service";
import Button from "../../../common/form/Button";
import RephraseButton from "../../../common/form/RephraseButton";
import { AutoComplete } from "primereact/autocomplete";

const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  letter-spacing: -0.03rem;
  margin-top: 10px;
`;

const Subtitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  letter-spacing: -0.02625rem;
`;

const UploadImage = styled.div`
  margin-top: 10px;
  width: 128px;
  height: 128px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--border-color);
  flex-direction: column;
  gap: 10px;
  transition-duration: 0.05s;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
    fill: var(--border-color);
  }

  span {
    font-weight: bold;
    color: var(--border-color);
  }

  &:hover {
    border: 2px dashed var(--primary-color);
    svg {
      fill: var(--primary-color);
    }

    span {
      color: var(--primary-color);
    }
  }
`;

const InnerText = styled.div`
  position: absolute;
  top: 0%;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(10, 37, 64);
  font-size: 12px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;

  border-radius: 6px 0 0 6px;
  letter-spacing: -0.1px;
`;

const TextInsideInput = styled.div`
  margin-top: 10px;
  position: relative;
  width: 100%;
  /* width: calc(100% - 171px); */

  & input {
    margin-top: 0;
    padding-left: 65px;
  }
`;

const ButtonStyle = styled(Button)`
  path {
    fill: "white" !important;
  }
`;

const businessCategories: BusinessCategory[] = [
  {
    category: "קמעונאות",
    types: [
      {
        label: "חנות קמעונאית - חנות בגדים, חנות צעצועים.",
        value: "retail_clothing_toys",
      },
      {
        label: "חנות מקוונת - אתר מכירות בגדים אונליין, חנות ספרים דיגיטליים.",
        value: "online_store",
      },
      {
        label: "עסק למכירת רכבים - סוכנות רכבים, ליסינג תפעולי.",
        value: "car_sales",
      },
    ],
  },
  {
    category: "שירותים אישיים",
    types: [
      {
        label: "נותן שירות - מספרה, קוסמטיקאית, יועץ עסקי.",
        value: "service_provider",
      },
      {
        label: "קליניקה רפואית - מרפאת שיניים, קליניקת פיזיותרפיה.",
        value: "medical_clinic",
      },
      {
        label: "עסק לתיקונים ושיפוצים - חשמלאי, אינסטלטור.",
        value: "repairs_renovations",
      },
    ],
  },
  {
    category: "מזון ומשקאות",
    types: [
      {
        label: "מסעדה/בית קפה - מסעדת אוכל מהיר, בית קפה מקומי.",
        value: "restaurant_cafe",
      },
    ],
  },
  {
    category: "לוגיסטיקה והובלה",
    types: [
      {
        label: "עסק לוגיסטיקה והובלה - חברת הובלות, שירותי שליחויות.",
        value: "logistics_business",
      },
    ],
  },
  {
    category: "טכנולוגיה וחדשנות",
    types: [
      {
        label:
          "סטארטאפ טכנולוגי - חברת פיתוח אפליקציות, פלטפורמת ניתוח נתונים.",
        value: "tech_startup",
      },
    ],
  },
  {
    category: "חינוך והדרכה",
    types: [
      {
        label: "מוסד חינוכי - בית ספר פרטי, מכון לימודים.",
        value: "educational_institution",
      },
    ],
  },
  {
    category: "פיננסים וייעוץ",
    types: [
      {
        label: "תאגיד פיננסי - בנק, חברת השקעות.",
        value: "financial_corporation",
      },
      {
        label: "חברה לייעוץ עסקי - יועץ שיווקי, יועץ ארגוני.",
        value: "business_consulting",
      },
    ],
  },
  {
    category: 'בנייה ופיתוח נדל"ן',
    types: [
      {
        label: 'חברת בנייה - קבלן בניין, חברת פיתוח פרויקטים נדל"ניים.',
        value: "construction_company",
      },
    ],
  },
  {
    category: "בידור ואירועים",
    types: [
      {
        label: "חברת בידור ואירועים - חברת הפקות אירועים, סוכנות אומנים.",
        value: "event_company",
      },
    ],
  },
  {
    category: "פרסום ושיווק",
    types: [
      {
        label: "חברת פרסום ושיווק - משרד פרסום, סוכנות דיגיטל.",
        value: "ad_agency",
      },
    ],
  },
  {
    category: "אינפלואנסר",
    types: [{ label: "אינפלואנסר", value: "influencer" }],
  },
];

interface BusinessType {
  label: string;
  value: string;
}

interface BusinessCategory {
  category: string;
  types: BusinessType[];
}

const BusinessDetails: React.FC = () => {
  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState<
    BusinessCategory[]
  >([]);

  const { register, control, formState, setValue, getValues } =
    useFormContext<CreateWebsiteRequestData>();

  const { errors, isLoading } = formState;
  const [isLoadingCrawler, setIsLoading] = useState(false);

  const onScanWebsite = useCallback(async () => {
    if (getValues("website.url") === "https://") return;
    setIsLoading(true);
    try {
      const scannedWebsiteMeta = await crollerService.scanWebsite(
        getValues("website.url")
      );

      setValue("website.name", scannedWebsiteMeta.name);
      setValue("website.description", scannedWebsiteMeta.description);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [getValues, setValue]);

  const searchBusinessTypes = (event: any) => {
    const query = event.query.toLowerCase();
    const filteredGroups = businessCategories
      .map((category) => {
        const filteredItems = category.types.filter((item) =>
          item.label.toLowerCase().includes(query)
        );
        return { category: category.category, types: filteredItems };
      })
      .filter((category) => category.types.length > 0);

    setFilteredBusinessTypes(filteredGroups);
  };

  return (
    <>
      <FormStyle>
        <div className="grid">
          <div className="col-12">
            <label htmlFor="username" className="mb-3">
              לוגו העסק
            </label>
            <AttachmentInput<CreateWebsiteRequestData> name="website.logoSrc"></AttachmentInput>
          </div>
          <div className="col-6">
            <label htmlFor="username">שם העסק</label>
            <InputText
              {...register("website.name", {
                required: {
                  message: "שם העסק שדה חובה",
                  value: true,
                },
              })}
              placeholder="איך העסק שלכם נקרא?"
            ></InputText>
          </div>
          <div className="col-6">
            <label htmlFor="username">אתר העסק</label>
            <TextInsideInput
              dir="ltr"
              className={errors.website?.url ? "p-invalid" : ""}
            >
              <InnerText>https://</InnerText>
              <InputText
                disabled={isLoading}
                {...register("website.url", {
                  setValueAs: (value: string) => {
                    const formatted = value
                      .replace("https://", "")
                      .replace("http://", "");
                    return formatted;
                  },
                  pattern: {
                    value: RegexValidations.url,
                    message: "כתובת האתר אינה חוקית",
                  },
                })}
                placeholder="yourwebsite.com"
                className={
                  "w-full unmask-clarity" +
                  (errors.website?.url ? "p-invalid" : "")
                }
              />
            </TextInsideInput>

            <ButtonStyle
              arrowPlacement="right"
              loading={isLoadingCrawler}
              onClick={onScanWebsite}
              disabled={!getValues("website.url")}
            >
              סרוק את האתר{" "}
            </ButtonStyle>
          </div>
          <div className="col-12">
            <label htmlFor="username">תיאור העסק</label>
            <Controller
              name="website.description"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "תיאור העסק שדה חובה",
                },
              }}
              render={({ field }) => (
                <>
                  <InputTextarea
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    placeholder="תיאור מפורט על העסק שלכם, מה אתם עושים, במה אתם מתמחים"
                  />
                  <RephraseButton
                    id="rephraseButton"
                    onChange={(newText: string) => field.onChange(newText)}
                    content={field.value}
                    prompt={
                      field.value
                        ? "אתה הולך לקבל טקסט המתאר עסק. התפקיד שלך הוא לשכתב בלבד את תיאור העסק הבא בצורה טובה יותר, רחבה ומקיפה יותר"
                        : "בלי לציין שם העסק וקהל יעד, הנחה אותי כיצד לכתוב תיאור מפורט של מה שהעסק שלי עושה. אין צורך בהקדמה, רק תן הדרכה ברורה קצרה מאוד בנקודות ותמציתית."
                    }
                  >
                    <span>
                      {field.value ? "נסח בשבילי מחדש" : "צור בשבילי"}
                    </span>
                  </RephraseButton>
                </>
              )}
            />
          </div>
          <div className="col-12">
            <Title>מה תחום העיסוק שלך?</Title>
            <Subtitle>
              ככה נדע הכי טוב ליצור לך את התוכן הטוב ביותר! המידע כמובן לא מופיע
              בשום מקום
            </Subtitle>
          </div>
          <div className="col-12">
            <label htmlFor="username">תחום עיסוק</label>
            <br />
            <Controller
              name="website.category"
              rules={{ required: "תחום עיסוק שדה חובה" }}
              control={control}
              render={({ field }) => (
                <AutoComplete
                  className="w-full"
                  id="businessType"
                  value={field.value}
                  suggestions={filteredBusinessTypes}
                  completeMethod={searchBusinessTypes}
                  field="label"
                  optionGroupLabel="category"
                  optionGroupChildren="types"
                  onFocus={(e) => searchBusinessTypes({ query: "" })}
                  delay={0}
                  onChange={(e) => field.onChange(e.value)}
                  placeholder="בחר סוג עסק"
                />
              )}
            />

            {/* <InputText
              {...register("website.category")}
              placeholder="בחרו את תחום העיסוק שלכם"
            ></InputText> */}
            {/* This is a dropdown */}
          </div>
        </div>
      </FormStyle>
    </>
  );
};

export default BusinessDetails;
