import { useEffect, useRef } from "react";

function useClickOutside({
  handleBlur,
  blockIndex,
}: {
  handleBlur: (blockIndex: number) => void;
  blockIndex: number;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        handleBlur(blockIndex);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleBlur]);
  return wrapperRef;
}
export default useClickOutside;
