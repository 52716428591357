// BlogPostSharedSettings.tsx
import React from "react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Chips } from "primereact/chips";
import CustomChip from "../../components/CustomChip";
import GenericDescriptionInput from "./GenericDescriptionInput";
import { DocumentEntity, DocumentEntityType } from "neword-core";

// Define the shape of your form data
interface FormData {
  inputParams: {
    keywords: string[];
    internalLinks: string[];
  };
}

const ChipsStyle = styled(Chips)`
  margin-top: 10px;
  width: 100%;
  color: purple !important;

  input {
    font-size: 14px !important;
    font-family: "Assistant" !important;
    &:placeholder {
      font-size: 14px !important;
      font-family: "Assistant" !important;
    }
  }

  .p-chips {
    color: purple !important;
  }

  input {
    border: none !important;
    height: 25px;
  }

  ul {
    border-radius: 12px;
    width: 100%;
    box-shadow: none !important;
    border-color: var(--border-color) !important;
  }

  li {
    border-color: purple !important;
    color: purple;
  }

  .p-chips-token-label {
    color: #741fff;
  }

  .p-chips-token {
    display: flex;
    align-items: center;
  }

  .p-chips-token .p-chip-remove {
    display: none; /* Hide the default remove icon */
  }
  .p-icon {
    display: none; /* Hide the default remove icon */
  }
`;
const SmallLabel = styled.label`
  font-family: "Assistant";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  letter-spacing: -0.36px;
  cursor: pointer;
`;

interface GenericInputProps<T> {
  name: Path<T>;
  label: string;

  placeholder?: string;
  inputStyle?: React.CSSProperties;
}

function DocChipInput<T extends FieldValues>({
  name,
  label,
  placeholder,
}: GenericInputProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <div className="w-full step-2-wt">
      <SmallLabel>{label}</SmallLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ChipsStyle
            value={field.value}
            onChange={(e) => field.onChange(e.value)}
            placeholder={placeholder}
            itemTemplate={(item: string) => (
              <CustomChip
                label={item}
                removable
                onRemove={() => {
                  const newValue = field.value.filter(
                    (value: string) => value !== item
                  );
                  field.onChange(newValue);
                }}
              />
            )}
          />
        )}
      />
    </div>
  );
}

export default DocChipInput;
