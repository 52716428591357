import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import { ReactComponent as EmojiPickerIcon } from "../../../assets/Icons/EmojiPicker.svg";
import EmojiPicker from "emoji-picker-react";
import { useFormContext, Path } from "react-hook-form";

const EditableDivStyled = styled.div`
  outline: none !important;
  min-height: 50px;
  width: 100%;

  &:empty:before {
    content: attr(placeholder);
    color: #aaa;
  }
`;

const Wrapper = styled.div`
  background: var(--light-bg);
  border-radius: 6px;
  padding: 10px;
`;

const EmojiPickerWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  svg {
    width: 20px;
    height: 20px;
    fill: #c2c2c2;
  }
`;

const EmojiPickerContainer = styled.div<{ show: boolean }>`
  position: absolute;
  bottom: 30px;
  right: 0;
  display: ${({ show }) => (show ? "block" : "none")};
  z-index: 1000;
`;

const CharacterCounter = styled.div`
  text-align: right;
  font-size: 12px;
  color: ${({ count }: { count: number }) => (count > 2200 ? "red" : "#888")};
  margin-top: 5px;
`;

function TextEditor({ name }: { name: Path<any> }) {
  const { setValue, getValues } = useFormContext();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const editorRef = useRef<HTMLDivElement | null>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);

  // Function to handle syncing input
  const handleInput = () => {
    if (editorRef.current) {
      const textContent = editorRef.current.innerText; // Ensure it's only text content

      // Update character count
      setCharacterCount(textContent.length);

      // Get the current object
      const currentObject = getValues(name);

      // Update only the message field in the object
      const updatedObject = { ...currentObject, message: textContent };

      // Set the updated object back into the form
      setValue(name, updatedObject);
    }
  };

  // Handle Emoji selection and update the div content
  const handleEmojiClick = (emojiObject: any) => {
    if (editorRef.current) {
      editorRef.current.innerText += emojiObject.emoji; // Append emoji to the div's text
      handleInput(); // Sync the div content with the form value
    }
    setShowEmojiPicker(false); // Close emoji picker after selecting an emoji
  };

  // Initialize the div with the message field value (for editing existing content)
  useEffect(() => {
    const initialValue = getValues(name); // Get the initial value for this form field

    if (
      initialValue &&
      typeof initialValue === "object" &&
      initialValue.message
    ) {
      if (editorRef.current) {
        editorRef.current.innerText = initialValue.message; // Set the initial content in the editable div
        setCharacterCount(initialValue.message.length); // Set initial character count
      }
    }
  }, [getValues, name]);

  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setShowEmojiPicker(false);
      }
    };

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  return (
    <Wrapper>
      <EditableDivStyled
        contentEditable="true"
        ref={editorRef}
        onInput={handleInput} // Sync content with form on input
      ></EditableDivStyled>
      <div className="flex justify-content-between align-items-center">
        <EmojiPickerWrapper>
          <EmojiPickerIcon
            style={{ cursor: "pointer" }}
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          />
          <EmojiPickerContainer ref={emojiPickerRef} show={showEmojiPicker}>
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </EmojiPickerContainer>
        </EmojiPickerWrapper>
        <CharacterCounter count={characterCount}>
          {characterCount}/2200
        </CharacterCounter>
      </div>
    </Wrapper>
  );
}

export default TextEditor;
