import React, { useState } from "react";
import styled from "styled-components";
import { Color } from "../../../core/theme/types/color";
import Badge from "../Badge";
import { ReactComponent as DownArrow } from "../../../assets/Icons/ArrowRight.svg";
import { PostStatusType } from "neword-core";

// Define styled components for dropdown
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownToggle = styled.div`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;

  color: #fff;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-top: 5px;
  z-index: 1000;
  width: max-content;
  display: flex;
  flex-direction: column;
`;

const IconStyle = styled.i`
  font-size: 12px;
  transition-duration: 0.1s;
  padding-right: 8px;
  color: black;
`;

const DropdownItem = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  padding: 10px;
  display: block;
`;

// Props for BadgeDropDown
interface DropdownOption {
  value: PostStatusType;
  label: string;
  color: Color;
}

interface CustomDropdownProps {
  value: PostStatusType;
  options: DropdownOption[];
  onChange: (value: PostStatusType) => void;
}

const BadgeDropDown: React.FC<CustomDropdownProps> = ({
  value,
  options,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (optionValue: PostStatusType) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const selectedOption = options.find((option) => option.value === value);

  return (
    <DropdownContainer>
      {/* Dropdown Toggle - Shows the selected status as a badge */}
      <DropdownToggle onClick={handleToggle}>
        {selectedOption ? (
          <>
            <Badge clickable large bgColor={selectedOption.color}>
              {selectedOption.label}
            </Badge>
            <IconStyle className="pi pi-angle-down"></IconStyle>
          </>
        ) : (
          <span>Select status</span>
        )}
      </DropdownToggle>

      {/* Dropdown Menu */}
      {isOpen && (
        <DropdownMenu>
          {options.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
            >
              <Badge clickable large bgColor={option.color}>
                {option.label}
              </Badge>
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default BadgeDropDown;
