import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import BlogPostSharedSettings from "./advencedSettings/BlogPostSharedSettings";
import BlogPostTipsDescription from "./formComponentes/BlogPostTipsDescription";
import BlogPostComparisonDescription from "./formComponentes/BlogPostComparisonDescription";
import BlogPostCaseStudyDescription from "./formComponentes/BlogPostCaseStudyDescription";
import CartAbandonmentMailDescription from "./formComponentes/CartAbandonmentMailDescription";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

export const flashSmsAccordions: AccordionProps[] = [
  {
    title: "תוכן לסמס מבצע בזק",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
        >
          name="inputParams.description"
          label="איזה מבצע אתם רוצים לקדם?"
          placeholder={`ההנחה או המבצע הספציפיים המוצעים (למשל, "50% הנחה"). הסיבה מאחורי המבצע (למשל, "מבצע לכבוד יום הולדת של לקוח", "לכבוד חג שבועות", "סייל סוף עונה", "תודה על נאמנותך").`}
        />
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
        >
          name="inputParams.redemptionMethod"
          label="כיצד ניתן לממש את המבצע"
          placeholder={`כיצד ניתן לממש את המבצע? (לדוג׳ קוד קופון:off20” או ״הנחה מתעדכנת לבד בעגלה״)`}
          hideRephrase
          inputStyle={{ height: 60 }}
        />{" "}
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
        >
          name="inputParams.duration"
          label="משך זמן המבצע"
          placeholder={`משך המבצע (למשל, "בשעתיים הבאות", "עד ה24.2"). שעת ההתחלה והסיום המדויקת, אם רלוונטי.`}
          inputStyle={{ height: 60 }}
        />
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.FLASH_SALE_SMS }
        >
          name="inputParams.cta"
          label="מהי הקריאה לפעולה בסמס?"
          placeholder="קישור לחנות או לדף ספציפי"
          hideRephrase
          inputStyle={{ height: 60 }}
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <small className="pr-2 mb-3">* ניתן לבחור יותר מאחד</small>
        <DocumentTone<
          DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
        >
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
