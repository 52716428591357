import { DocumentEntity, DocumentEntityType } from "neword-core";
import { PostData } from "neword-core/dist/cjs/entities/post/postData";

export const convertDocToPostParams = (
  doc: DocumentEntity,
  blockIndex: number
): PostData => {
  switch (doc.type) {
    case DocumentEntityType.FACEBOOK_POST_CAPTION:
      return {
        type: "FACEBOOK_POST",
        message: doc.output[blockIndex].postCaption,
        published: false,
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
      };

    case DocumentEntityType.FACEBOOK_POST_IDEA:
      return {
        type: "FACEBOOK_POST",
        message: doc.output[blockIndex].postIdea,
        published: false,
        imageUrls: [],
      };
    case DocumentEntityType.FACEBOOK_PAGE_ABOUT:
      return {
        type: "FACEBOOK_POST",
        message: doc.output[blockIndex].pageAbout,
        published: false,
        imageUrls: [],
      };
    case DocumentEntityType.FACEBOOK_SPONSORED_AD:
      return {
        type: "FACEBOOK_POST",
        message: doc.output[blockIndex].captionText,
        published: false,
        imageUrls: [],
      };

    case DocumentEntityType.INSTAGRAM_CAPTION:
      return {
        type: "INSTAGRAM_POST",
        media_type: "IMAGE",
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
        media_ids: [],
        message: doc.output[blockIndex].captionText,

        // permalink: "https://www.instagram.com/p/1234567890/",
      };
    case DocumentEntityType.INSTAGRAM_POST_IDEA:
      return {
        type: "INSTAGRAM_POST",
        media_type: "IMAGE",
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
        media_ids: [],
        message: doc.output[blockIndex].ideaDescription,

        // permalink: "https://www.instagram.com/p/1234567890/",
      };
    case DocumentEntityType.INSTAGRAM_SPONSORED_AD:
      return {
        type: "INSTAGRAM_POST",
        media_type: "IMAGE",
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
        media_ids: [],
        message: doc.output[blockIndex].primaryText,

        // permalink: "https://www.instagram.com/p/1234567890/",
      };
    case DocumentEntityType.INSTAGRAM_BIO_DESCRIPTION:
      return {
        type: "INSTAGRAM_POST",
        media_type: "IMAGE",
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
        media_ids: [],
        message: doc.output[blockIndex].bioDescription,
      };
    case DocumentEntityType.LINKEDIN_POST_CAPTION:
      return {
        type: "LINKEDIN_POST",
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
        message: doc.output[blockIndex].postCaption,
      };
    case DocumentEntityType.LINKEDIN_PAGE_ABOUT:
      return {
        type: "LINKEDIN_POST",
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
        message: doc.output[blockIndex].pageAbout,
      };
    case DocumentEntityType.LINKEDIN_POST_IDEA:
      return {
        type: "LINKEDIN_POST",
        imageUrls: doc.inputParams.imageUrl ? [doc.inputParams.imageUrl] : [],
        message: doc.output[blockIndex].postIdea,
      };
    default:
      return {
        type: "FACEBOOK_POST",
        message: "",
        published: false,
        imageUrls: [],
      };
  }
};
