import React from "react";
import Card from "./common/Card";
import { Skeleton } from "primereact/skeleton";

interface BlockLoadingProps {
  columns: number; // Define the columns prop
}

const BlockLoading: React.FC<BlockLoadingProps> = ({ columns }) => {
  // Create an array of skeleton blocks based on the number of columns
  const skeletons = Array.from({ length: columns }, (_, index) => (
    <div className={`col-${12 / columns}`} key={index}>
      <Card>
        <div className="flex mb-3">
          <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
        <div className="flex justify-content-between mt-3">
          <Skeleton width="4rem" height="2rem"></Skeleton>
          <Skeleton width="4rem" height="2rem"></Skeleton>
        </div>
      </Card>
    </div>
  ));

  return <div className="grid w-full">{skeletons}</div>;
};

export default BlockLoading;
