import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import { AccordionProps } from "./types/AccordionProps";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

export const trafficEmailAccordions: AccordionProps[] = [
  {
    title: "תיאור התוכן",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.TRAFFIC_EMAIL }
        >
          name="inputParams.contentTopic"
          label="מהו נושא התוכן שתרצו לשתף?"
          placeholder="לדוגמה ״הטרנדים החמים ביותר בהייטק בחודש האחרון״"
          inputStyle={{ height: 45 }}
        />

        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.TRAFFIC_EMAIL }
        >
          name="inputParams.cta"
          label="מהי הקריאה לפעולה העיקרית במייל שיתוף התוכן?"
          placeholder="לינק לתוכן אליו תרצו למשוך את הטראפיק, לדוגמה ״ביקור בלינק הבא: https://neword/product.ai”"
          hideRephrase
          inputStyle={{ height: 60 }}
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <small className="pr-2 mb-3">* ניתן לבחור יותר מאחד</small>
        <DocumentTone<
          DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
        >
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
