import { Divider } from "primereact/divider";
import { PostEntity, PostEntityType } from "neword-core";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FieldErrors,
  FieldValues,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import AttachmentInput from "../../common/form/AttachmentInput";
import IgPreview from "../postPreviews/IgPreview";
import { useRef, useEffect, useMemo, useState } from "react";
import { ReactComponent as EmojiPickerIcon } from "../../../assets/Icons/EmojiPicker.svg";
import CardTitle from "../../common/CardTitle";
import EmojiPicker from "emoji-picker-react";
import InstagramPreview from "../../../assets/images/instagramPreview.png"; // Add Instagram preview image
import TextEditor from "../../common/publishPostModal/TextEditor";
import LocationDropdown from "../../common/publishPostModal/LocationDropdown";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */

  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }

  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background: #fcf6f7;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;

function InstagramPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  const { posts = [] } = useWatch({ control }); // Ensuring that posts has a default value

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");

  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Initial adjustment on mount or when post content changes
  }, [posts]);

  return (
    <div className="grid m-0">
      <div className="col-6 p-0">
        <ImageWrapper className="p-4">
          <IgPreview name={name} />
        </ImageWrapper>
      </div>
      <div className="col-6 p-0">
        <div className="media p-2 mt-4">
          <CardTitle title="פרסום פוסט באינסטגרם" />
          <LabelStyled>הוספת מדיה</LabelStyled>
          {/* <AttachmentInput<any> name={`${name}.imageUrl`} /> */}
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            validate={(value) => {
              let errorText = "";

              if (value.length > 10) {
                errorText = "מותר עד 10 פריטים";
              }
              setImageUrlError(errorText);
              if (errorText === "") {
                return true;
              }

              return errorText;
            }}
          />
          <span>{imageUrlError}</span>
        </div>
        <Divider />

        <div className="post p-2 mb-4">
          <LabelStyled>תוכן הפוסט</LabelStyled>
          <TextEditor name={name} />
          <LabelStyled className="mt-2">הוסף מיקום</LabelStyled>
          <LocationDropdown name={`${name}.location`} />
        </div>
      </div>
    </div>
  );
}

export default InstagramPostForm;
